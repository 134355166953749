import { createApp } from 'vue'
import App from './App.vue';
// import VueGtag from 'vue-gtag'
import { createRouter, createWebHistory } from 'vue-router';
import { MotionPlugin } from '@vueuse/motion';

import 'animate.css'
import { animate } from "popmotion"

animate({
    from: 0,
    to: 100,
    onUpdate: latest => console.log(latest)
})

import VueScrollTo from 'vue-scrollto'

import HomePage from './page/HomePage.vue'
import InfoPage from './page/InfoPage.vue'
import FictionDiurne from './page/works/FictionDiurne.vue'
import ChampDeRiviere from './page/works/ChampDeRiviere.vue'
import PetiteChoseSansValeur from './page/works/PetiteChoseSansValeur.vue'
import Puzzle from './page/works/Puzzle.vue'

const routes = [
    {
        name: 'HomePage',
        path: '/',
        component: HomePage,
        meta: { transition: 'slideHome', title: 'Jason Pumo | Portfolio' },
    },
    {
        name: 'InfoPage',
        path: '/info',
        component: InfoPage,
        meta: { transition: 'slideUp', title: 'Info' },
    },
    {
        name: 'ChampDeRiviere',
        path: '/champ-de-riviere',
        component: ChampDeRiviere,
        meta: { transition: 'slideUp', title: 'Champ de Rivière' },
    },
    {
        name: 'PetiteChoseSansValeur',
        path: '/petite-chose-sans-valeur',
        component: PetiteChoseSansValeur,
        meta: { transition: 'slideUp', title: 'Petite Chose Sans Valeur' },
    },
    {
        name: 'PuzzlePage',
        path: '/puzzle',
        component: Puzzle,
        meta: { transition: 'slideUp', title: 'Puzzle' },
    },
    {
        name: 'FictionDiurne',
        path: '/fiction-diurne',
        component: FictionDiurne,
        meta: { transition: 'slideUp', title: 'Fiction Diurne' },
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to) => {
    document.title = to.meta.title
})

export default router

const VueApp = createApp(App)

VueApp.use(router, VueScrollTo, MotionPlugin)

// Configuration VueAnalytics
// VueApp.use(VueGtag, {
//     id: 'G-4S9HGEZ1KG',
//     router
//   });
  
VueApp.mount('#app')