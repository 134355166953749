<template>
  <div class="move" @contextmenu.prevent="showEmailDiv">

    <div class="main--gallery theme--two" @touchstart="handleTouchStart" @gesturechange="handleGestureChange"
      @touchmove="handleTouchMove" @touchend="handleTouchEnd" ref="elementToDisableSwipeAndZoom"
      style="z-index: 10; background: none !important">

      <div ref="descriptionFirst" class="dark--grain description item current" @click.stop="disableClick">

        <div class="bottom-right">
          <div class="main--img one">
            <div class="layer--riviere"></div>
            <div class="layer--black"></div>
            <img ref="pictureItem" src="../../assets/img/champ-de-riviere/jason-pumo_champ-de-riviere_main.jpg" alt="" />
          </div>
        </div>
      </div>

      <figure v-motion="fadeText" v-for="(image, index) in images"
        :key="index === images.length - 1 ? 'lastPicture' : index" :ref="'pictureItem' + index"
        :class="['picture FADEIN red-pointer', 'item antique center-self', image.class, { 'lastItem': index === images.length - 1 }]">
        <div :class="['image-container red-pointer gallery--one', image.classContainer]">
          <div :class="['image-item leg-left', image.classDiv1]">
            <img v-if="image.src" :src="image.src" :class="image.class1" :alt="altText" />
          </div>
          <div :class="['image-item leg-right', image.classDiv2]" v-if="image.hasSecondImage">
            <img :src="image.src2" :class="image.class2" @click="zoomImg(index)" :alt="altText" />
          </div>
          <div :class="['image-item leg-third', image.classDiv3]" v-if="image.hasThirdImage">
            <img :src="image.src3" :class="image.class3" @click="zoomImg(index)" :alt="altText" />
          </div>
          <div :class="['image-item leg-fourth', image.classDiv4]" v-if="image.hasFourthImage">
            <img :src="image.src4" :class="image.class4" @click="zoomImg(index)" :alt="altText" />
          </div>
          <div :key="'textSection' + index" v-if="image.hasText" :class="['centered-text', image.classText]">
            <h1 v-html="image.text"></h1>
          </div>
          <div :class="['caption', image.classCaption]">
            <figcaption v-html="image.title" :class="['desktop', image.classText]"></figcaption>
          </div>
        </div>
        <figcaption v-html="image.title" :class="['mobile', image.classText]"></figcaption>
      </figure>

    </div>
    <MenuGallery />

    <div class="figure-counter pages opacity-zero" style="pointer-events: none" v-motion="fade4">
      <span class="current-counter">{{ currentImageIndex }}</span><span class="slash-counter"> — </span> <span
        class="total-counter">{{ totalFigures }}</span>
    </div>

    <div class="red-pointer loader--gif" style="opacity: 1 !important; z-index: 1;">
      <h1 style="display: none !important">{{ documentTitle }}</h1>
      <div class="img--home">
        <div class="layer--riviere"></div>
        <div class="layer--black"></div>
        <img style="display: block !important" :key="currentImage"
          src="../../assets/img/champ-de-riviere/jason-pumo_champ-de-riviere_main.jpg" />
      </div>
    </div>

  </div>
</template>

<script>
import GalleryScript from '../../directives/GalleryScript.js';
import GalleryScriptObserver from '../../directives/GalleryScriptObserver.js';
import ThemeTwo from '../../directives/ThemeTwo.js';
import { fade2, fadeText, fade3, fade4 } from '../../directives/AnimationHome.js';

import MenuGallery from "../menu/MenuGallery.vue";
// import { pageview } from 'vue-gtag'

export default {
  name: 'ChampDeRiviere',
  components: { MenuGallery },
  mixins: [GalleryScript, GalleryScriptObserver, ThemeTwo],
  data() {
    return {
      documentTitle: document.title,
      currentImageIndex: 0,
      currentIndex: null,
      fade2, fadeText, fade3, fade4,
      altText: "Jason Pumo, Fiction Diurne, photographie narrative, réel, couleur, Arles, France, mouvement, Terre, Soleil, Lune, temps, art, artiste, argentique, analog, film",
      images: [

        {
          hasText: true, src: '', title: '', class: 'cartel-text xl-pad-left xl-pad-right first red-pointer', classText: 'mobile-fit',
          text: `<span class="titre">Champ de Rivière</span><span class="annee">— &nbsp; 2022</span>
  <div class="ensemble">
    <div class="francais" style="text-indent:0 !important; width: max-content; font-family:'indivisble'">
  <p style="text-indent:0 !important;">Une partie du projet est à découvrir à l'Espace Van Gogh (Arles), du 5 au 20 octobre 2023<br />
    Exposition 'En longeant l'orée', La Galerie Itinérante</p>
    </div>`
        }
      ],
    }
  },
  // setup() {
  //   const track = () => {
  //     pageview({ page_path: '/champ-de-riviere' });
  //     pageview('/champ-de-riviere');
  //   }
  //   return {
  //     track
  //   }
  // },
  mounted() {
    document.querySelector('.figure-counter').style.display = 'none !important';
    document.querySelector('.info--edition').classList.add('index-of-champ-de-riviere');

    const IndexChamp = document.querySelector('.index-of-champ-de-riviere');

    function gtag() {
      window.dataLayer.push(arguments);
    }

    IndexChamp.addEventListener('click', () => {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'index_champ_de_riviere', {
        'event_category': 'Click',
        'event_label': 'click_on_index_champ_de_riviere'
      });
    });

    // const lastItem = document.querySelector('.lastItem');

    // if (lastItem.classList.contains("current")) {
    //   // Replace 'track-category' and 'track-action' with your own values
    //   gtag('event', 'reach_end_champ_de_riviere', {
    //     'event_category': 'Click',
    //     'event_label': 'reach_end_champ_de_riviere'
    //   });
    // }
  },
  unmounted() {
    document.querySelector('.info--edition').classList.remove('index-of-champ-de-riviere');
  }
}
</script>