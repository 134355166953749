<template>
  <div class="cursor"></div>
  <div class="cursor-follower"></div>
  <div id="email" class="context-cursor"><a href="mailto:mail@jasonpumo.com" class="tag_mail_droit">mail@jasonpumo.com</a>
  </div>

  <router-view v-slot="{ Component }">
    <transition :name="transitionName" v-if="transitioning">
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script>
import HomePage from "./page/HomePage.vue";

export default {
  name: 'App',
  components: {
    HomePage,
  },
  data() {
    return {
      transitioning: false,
      previousRoute: null
    }
  },
  created() {
    this.previousRoute = this.$route.path;
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    }
  },
  watch: {
    '$route'() {
      this.transitioning = true
    },
    currentRoute(newRoute) {
      if (newRoute === "/" && this.previousRoute === "/info" && this.previousRoute === "/info") {
        this.transitionName = "slideHome";
      } else {
        this.transitionName = this.$route.meta.transition;
      }
      this.previousRoute = newRoute;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.transitioning = false
    next()
  },
  mounted() {
    // CONTEXT MENU
    let emailDiv = document.getElementById('email');
    let allImg = document.querySelectorAll('img');

    allImg.forEach(img => {
      img.addEventListener('mouseleave', function () {
        emailDiv.style.display = "none";
      });
    });

    // CURSOR PARAMETERS
    const cursor = document.querySelector(".cursor");
    const follower = document.querySelector(".cursor-follower");

    let posX = 0;
    let posY = 0;

    let mouseX = 0;
    let mouseY = 0;

    // Use the "requestAnimationFrame" method for smooth animation
    function animateCursor() {
      posX += (mouseX - posX) / 7;
      posY += (mouseY - posY) / 7;

      follower.style.left = `${posX - 17}px`;
      follower.style.top = `${posY - 17}px`;

      cursor.style.left = `${mouseX}px`;
      cursor.style.top = `${mouseY}px`;

      // Call "requestAnimationFrame" recursively to animate the cursor
      window.requestAnimationFrame(animateCursor);
    }
    animateCursor();

    document.addEventListener("mousemove", function (e) {
      mouseX = e.pageX;
      mouseY = e.pageY;
    });

    //GTAG EVENT
    const DroitMail = document.querySelector('.tag_mail_droit');
    
    function gtag() {
      window.dataLayer.push(arguments);
    }

    DroitMail.addEventListener('click', () => {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'right_click_mail', {
        'event_category': 'Click_Droit_Mail',
        'event_label': 'click_on_mail_clique_droit'
      });
    });

    document.addEventListener('contextmenu', function(event) {
    // Prevent the default context menu from appearing
    event.preventDefault();

    gtag('event', 'right_click', {
        'event_category': 'Click',
        'event_label': 'clique_droit'
      });

  });

  }
}
</script>

<style lang="scss">
@import url("https://use.typekit.net/ptd8amo.css");

@import url('./style/reset.css');
@import url('./style/base.css');
@import url('./style/cursor.css');
@import url('./style/transitions.css');

@import url('./style/home.css');
@import url('./style/info.css');
@import url('./style/gallery.css');
@import url('./style/display-gallery.css');
@import url('./style/animations.css');


@import url('./style/responsive-base.css');
@import url('./style/responsive-home.css');
@import url('./style/responsive-info.css');
@import url('./style/responsive-gallery.css');
@import url('./style/responsive-transitions.css');
</style>
