export default {
  methods: {
    activateLeftKey() {
      const event = new KeyboardEvent('keydown', {
        keyCode: 37,
        key: 'ArrowLeft',
        code: 'ArrowLeft',
      });
      window.dispatchEvent(event);
      this.buttonScrollZIndex = 600;

      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag('event', 'prev_all_pages', {
        'event_category': 'Click',
        'event_label': 'click_to_button_left_all_pages'
      });

      if (this.$route.name === 'FictionDiurne') {
        gtag('event', 'prev_fiction_diurne', {
          'event_category': 'Click',
          'event_label': 'click_to_button_left_fiction_diurne'
        });
      }

      if (this.$route.name === 'PuzzlePage') {
        gtag('event', 'prev_puzzle', {
          'event_category': 'Click',
          'event_label': 'click_to_button_left_puzzle'
        });
      }

      if (this.$route.name === 'PetiteChoseSansValeur') {
        gtag('event', 'prev_petite_chose_sans_valeur', {
          'event_category': 'Click',
          'event_label': 'click_to_button_left_petite_chose_sans_valeur'
        });
      }
    },
    activateRightKey() {
      const event = new KeyboardEvent('keydown', {
        keyCode: 39,
        key: 'ArrowRight',
        code: 'ArrowRight',
      });
      window.dispatchEvent(event);
      this.buttonScrollZIndex = 600;

      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag('event', 'next_all_pages', {
        'event_category': 'Click',
        'event_label': 'click_to_button_right_all_pages'
      });

      if (this.$route.name === 'PetiteChoseSansValeur') {
        gtag('event', 'next_petite_chose_sans_valeur', {
          'event_category': 'Click',
          'event_label': 'click_to_button_right_petite_chose_sans_valeur'
        });
      }

      if (this.$route.name === 'PuzzlePage') {
        gtag('event', 'next_puzzle', {
          'event_category': 'Click',
          'event_label': 'click_to_button_right_puzzle'
        });
      }

      if (this.$route.name === 'FictionDiurne') {
        gtag('event', 'next_fiction_diurne', {
          'event_category': 'Click',
          'event_label': 'click_to_button_right_fiction_diurne'
        });
      }
    },
    onKeydown(event) {
      if (event.keyCode === 37) {
        this.activateLeftKey();
      } else if (event.keyCode === 39) {
        this.activateRightKey();
      }
    },
    fadeInToCurrent() {
      const gallery = document.querySelector('.main--gallery');
      const current = document.querySelector('.current');
      const scrollAmount = current.offsetLeft - gallery.offsetWidth / 2 + current.offsetWidth / 2;
      gallery.scroll({
        left: scrollAmount,
        behavior: 'instant'
      });
    },
    infoYear() {
      const figcaptions = document.querySelectorAll('.main--gallery.grid-view figcaption');
      figcaptions.forEach(figcaption => figcaption.classList.toggle('opacity-one'));
    },
    showIndexEdition() {
      const menuEdition = document.querySelector('.menu--edition');
      const GridView = document.querySelector('.main--gallery');
      const cursor = document.querySelector(".cursor");
      const follower = document.querySelector(".cursor-follower");
      const buttonScroll = document.querySelector('.button-scroll');
      const indexGalleryOverlay = document.querySelector('.menu--index.desktop .index-gallery-overlay.fromEdition');
      const menuIndexOverlay = document.querySelector('.menu--index.desktop');
      const loader = document.querySelector('.loader--gif');
      const titreSite = document.querySelector('.titre--site');
      const infoText = document.querySelector('.back--text');

      loader.classList.add('overlay--loader');
      cursor.classList.remove("active5-white");
      cursor.classList.remove("cursor-blanche");
      follower.classList.remove("cursor-blanche2");
      menuEdition.classList.add('opacity-zero');
      follower.classList.add("cursor--index");
      titreSite.classList.add('opacity-zero');
      buttonScroll.classList.add('opacity-zero');

      setTimeout(() => {
        GridView.classList.toggle("grid-view");
      }, 500);

      setTimeout(() => {
        this.fadeInToCurrent();
        infoText.classList.remove('opacity-zero');
        infoText.style.display = "";
      }, 1000);

      setTimeout(() => {
        loader.classList.remove('overlay--loader');
      }, 1500);

      setTimeout(() => {
        menuIndexOverlay.classList.remove('opacity-zero');
        indexGalleryOverlay.classList.remove('opacity-zero');
      }, 1600);

    },
    hideIndexEdition() {
      const menuEdition = document.querySelector('.menu--edition');
      const GridView = document.querySelector('.main--gallery');
      const follower = document.querySelector(".cursor-follower");
      const cursor = document.querySelector(".cursor");
      const transImg = document.querySelectorAll('figure.item');
      const buttonScroll = document.querySelector('.button-scroll');
      const indexGalleryOverlay = document.querySelector('.menu--index.desktop .index-gallery-overlay.fromEdition');
      const loader = document.querySelector('.loader--gif');
      const titreSite = document.querySelector('.titre--site');
      const infoText = document.querySelector('.back--text');
      const firstFigureElement = document.querySelector('figure.item.first');
      
      loader.classList.add('overlay--loader');
      menuEdition.classList.remove('opacity-zero');
      follower.classList.remove("cursor--index");
      titreSite.classList.remove('opacity-zero');
      buttonScroll.classList.remove('opacity-zero');
      infoText.classList.add('opacity-zero');

      setTimeout(() => {
        GridView.scrollTo({ top: 0 });
      }, 250);

      setTimeout(() => {
        indexGalleryOverlay.classList.toggle('opacity-zero');
        GridView.classList.toggle("grid-view");
        transImg.forEach(img => img.style.transition = "");
        firstFigureElement.classList.add('current');
      }, 500);

      setTimeout(() => {
        this.fadeInToCurrent();
      }, 1000);

      setTimeout(() => {
        loader.classList.remove('overlay--loader');
        cursor.classList.add('cursor-blanche');
        follower.classList.add('cursor-blanche2');
      }, 1500);

      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag('event', 'hide_index_all_pages', {
        'event_category': 'Click',
        'event_label': 'click_to_hide_index_all_pages'
      });

      if (this.$route.name === 'FictionDiurne') {
        gtag('event', 'hide_index_fiction_diurne', {
          'event_category': 'Click',
          'event_label': 'click_to_hide_index_fiction_diurne'
        });
      }

      if (this.$route.name === 'PetiteChoseSansValeur') {
        gtag('event', 'hide_index_petite_chose_sans_valeur', {
          'event_category': 'Click',
          'event_label': 'click_to_hide_index_petite_chose_sans_valeur'
        });
      }

      if (this.$route.name === 'PuzzlePage') {
        gtag('event', 'hide_index_puzzle', {
          'event_category': 'Click',
          'event_label': 'click_to_hide_index_puzzle'
        });
      }
    },
    hideIndexEditionDesk() {
      const menuEdition = document.querySelector('.menu--edition');
      const GridView = document.querySelector('.main--gallery');
      const follower = document.querySelector(".cursor-follower");
      const cursor = document.querySelector(".cursor");
      const buttonScroll = document.querySelector('.button-scroll');
      const indexGalleryOverlay = document.querySelector('.menu--index.desktop .index-gallery-overlay.fromEdition');
      const loader = document.querySelector('.loader--gif');
      const titreSite = document.querySelector('.titre--site');
      const infoText = document.querySelector('.back--text');

      loader.classList.add('overlay--loader');

      menuEdition.classList.remove('opacity-zero');
      follower.classList.remove("cursor--index");
      titreSite.classList.remove('opacity-zero');
      buttonScroll.classList.remove('opacity-zero');

      infoText.classList.add('opacity-zero');

      setTimeout(() => {
        indexGalleryOverlay.classList.toggle('opacity-zero');
        GridView.classList.toggle("grid-view");
      }, 500);

      setTimeout(() => {
        this.fadeInToCurrent();
      }, 1000);

      setTimeout(() => {
        loader.classList.remove('overlay--loader');
        cursor.classList.add('cursor-blanche');
        follower.classList.add('cursor-blanche2');
      }, 1500);

      
      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag('event', 'hide_index_all_pages', {
        'event_category': 'Click',
        'event_label': 'click_to_hide_index_all_pages'
      });

      if (this.$route.name === 'FictionDiurne') {
        gtag('event', 'hide_index_fiction_diurne', {
          'event_category': 'Click',
          'event_label': 'click_to_hide_index_fiction_diurne'
        });
      }

      if (this.$route.name === 'PetiteChoseSansValeur') {
        gtag('event', 'hide_index_petite_chose_sans_valeur', {
          'event_category': 'Click',
          'event_label': 'click_to_hide_index_petite_chose_sans_valeur'
        });
      }

      if (this.$route.name === 'PuzzlePage') {
        gtag('event', 'hide_index_puzzle', {
          'event_category': 'Click',
          'event_label': 'click_to_hide_index_puzzle'
        });
      }
    },
    navigateToHome() {
      const self = this;
      const background = document.querySelector('.galleryBG');
      const loader = document.querySelector('.loader--gif');
      const loaderH1 = document.querySelector('.loader--gif h1');
      const cursor = document.querySelector(".cursor");
      const follower = document.querySelector(".cursor-follower");
      const topHome = document.querySelector('.titre--site');
      const topHomeImg = document.querySelector('.titre--site img');

      if (window.innerWidth > 1024) {
        loaderH1.style.display = 'none';
        loader.classList.add('overlay--loader');
        background.style.backgroundColor = "black";
        background.style.transition = "all 0.5s ease";
        cursor.classList.remove('cursor-blanche');
        follower.classList.remove('cursor-blanche2');
        topHome.classList.add('opacity-zero');
        topHomeImg.style.display = "none";
        setTimeout(function () {
          self.$router.push('/');
        }, 700);
      }
      if (window.innerWidth <= 1024) {
        loaderH1.style.display = 'none';
        loader.classList.add('overlay--loader');
        background.style.backgroundColor = "black";
        background.style.transition = "all 0.5s ease";
        cursor.classList.remove('cursor-blanche');
        follower.classList.remove('cursor-blanche2');
        setTimeout(function () {
          self.$router.push('/');
        }, 400);
      }
    }
  },
  mounted() {
    const buttonLeft = document.querySelector('.button-left');
    const cursor = document.querySelector(".cursor");
    const follower = document.querySelector(".cursor-follower");
    const topHome = document.querySelector('.titre--site');

    // BACK HOME GO HOME
    const goBack = document.querySelector('.button-left');
    const leftDevice = document.querySelector('.left--device');
    const rightDevice = document.querySelector('.right--device');

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          if (goBack.classList.contains('goHome')) {
            goBack.removeEventListener('click', this.activateLeftKey);
            goBack.addEventListener('click', this.navigateToHome);
            leftDevice.style.opacity = '0';
          } else {
            goBack.removeEventListener('click', this.navigateToHome);
            goBack.addEventListener('click', this.activateLeftKey);
            leftDevice.style.opacity = '1';
          }
        }
      }
    });
    observer.observe(goBack, { attributes: true });

    const goBack2 = document.querySelector('.button-right');
    const observer2 = new MutationObserver((mutationsList2) => {
      for (const mutation2 of mutationsList2) {
        if (mutation2.type === 'attributes' && mutation2.attributeName === 'class') {
          if (goBack2.classList.contains('end')) {
            goBack2.removeEventListener('click', this.activateRightKey);
            goBack2.addEventListener('click', this.navigateToHome);
            rightDevice.style.opacity = '0';
          } else {
            goBack2.removeEventListener('click', this.navigateToHome);
            rightDevice.style.opacity = '1';
          }
        }
      }
    });
    observer2.observe(goBack2, { attributes: true });

    buttonLeft.classList.add('debut');
    setTimeout(() => {
      topHome.classList.remove('pointer-events');
    }, 1000);

    // CUSTOM CURSOR 1
    const HMobile = document.querySelectorAll(".link3");
    HMobile.forEach(link3 => {
      link3.addEventListener("mouseenter", function () {
        cursor.classList.add("active-Edition");
        follower.classList.add("active3");
      });
      link3.addEventListener("mouseleave", function () {
        cursor.classList.remove("active-Edition");
        follower.classList.remove("active3");
      });
    });

    // CUSTOM CURSOR 1
    const linksEdition = document.querySelectorAll(".menu--edition");
    linksEdition.forEach(link2 => {
      link2.addEventListener("mouseenter", function () {
        cursor.classList.add("active-Edition");
        follower.classList.add("active3");
      });
      link2.addEventListener("mouseleave", function () {
        cursor.classList.remove("active-Edition");
        follower.classList.remove("active3");
      });
    });
  },
  unmounted() {
    const cursor = document.querySelector(".cursor");
    const follower = document.querySelector(".cursor-follower");
    const topHome = document.querySelector('.titre--site');
    
    topHome.removeEventListener('click', function () {
      cursor.classList.remove('cursor-blanche');
      follower.classList.remove('cursor-blanche2');
      cursor.classList.remove("active6-2");
      cursor.classList.remove("active6");
      follower.classList.remove("active3");
      topHome.classList.add('opacity-zero');
    });
  }
}