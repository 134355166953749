export default {
  methods: {
    fadeInToCurrent() {
      const gallery = document.querySelector('.main--gallery');
      const current = document.querySelector('.current');
      const scrollAmount = current.offsetLeft - gallery.offsetWidth / 2 + current.offsetWidth / 2;
      gallery.scroll({
        left: scrollAmount,
        behavior: 'smooth'
      });

      if (window.innerWidth <= 1024) {
        gallery.scroll({
          left: scrollAmount,
          behavior: 'instant'
        });
      }
    },
    jumpToCurrent() {
      const gallery = document.querySelector('.main--gallery');
      const current = document.querySelector('.current');
      const scrollAmount = current.offsetLeft - gallery.offsetWidth / 2 + current.offsetWidth / 2;
      gallery.scroll({
        left: scrollAmount,
        behavior: 'instant'
      });
    },
    activateRightKeyJump() {
      const current = document.querySelector('.current');
      const rightDevice = document.querySelector('.right--device');

      rightDevice.style.color = '#111';
      setTimeout(() => {
        rightDevice.style.color = '';
      }, 250);

      if (current.nextElementSibling) {
        current.nextElementSibling.classList.add('current');
        current.classList.remove('current');
        this.jumpToCurrent();
        if (window.innerWidth <= 1024) {
          this.jumpToCurrent();
        }
      }
      this.endArrow();

      if (this.currentImageIndex < this.images.length - 0) {
        this.currentImageIndex++;
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.activateRightKeyJump();
    }, 1150);
    setTimeout(() => {
      this.activateLeftKey();
    }, 1200);
  },
  unmounted() {
  }
}