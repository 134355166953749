export default {
  computed: {
    currentNumber() {
      const currentNumber = this.currentImageIndex;
      return currentNumber === 1 ? 1 : Math.max(1, currentNumber);
    }, totalFigures() {
      return this.images.length;
    },
    currentFigureCaption() {
      if (this.images.length > 0) {
        const currentImage = this.images[this.currentFigureIndex];
        return currentImage ? currentImage.title : ``;
      }
      return "";
    }
  },
  watch: {
    currentImageIndex(newValue) {
      // Update currentNumber here based on newValue
      this.currentNumber = newValue === 1 ? 1 : Math.max(1, newValue);
    },
  },
  data() {
    return {
      startX: null,
      swipeHandled: false,
      isZooming: false,
      swipeTimer: null,
    }
  },
  methods: {
    scrollToCurrent() {
      const gallery = document.querySelector('.main--gallery');
      const current = document.querySelector('.current');
      const scrollAmount = current.offsetLeft - gallery.offsetWidth / 2 + current.offsetWidth / 2;
      const behavior = gallery.classList.contains('fade--gallery') ? 'instant' : 'smooth';
      gallery.scroll({
        left: scrollAmount,
        behavior: behavior
      });
    },
    fadeToCurrent() {
      const gallery = document.querySelector('.main--gallery');
      const current = document.querySelector('.current');
      const scrollAmount = current.offsetLeft - gallery.offsetWidth / 2 + current.offsetWidth / 2;
      gallery.scrollLeft = scrollAmount;
      setTimeout(() => {
        gallery.classList.add('fade-in');
      }, 500);
      setTimeout(() => {
        gallery.classList.remove('fade');
        gallery.classList.remove('fade-in');
      }, 1500);
    },
    debutArrow() {
      const current = document.querySelector('.current');
      const pictureItems = document.querySelectorAll('.item');
      const firstPictureIndex = -2;
      const currentPictureIndex = Array.from(pictureItems).indexOf(current);
      const buttonRight = document.querySelector('.button-right');
      const leftDevice = document.querySelector('.left--device');

      if (currentPictureIndex === firstPictureIndex) {
        buttonRight.classList.add('debut');
        leftDevice.style.opacity = '0';
      } else {
        buttonRight.classList.remove('debut');
        leftDevice.style.opacity = '1';
      }
    },
    endArrow() {
      const current = document.querySelector('.current');
      const pictureItems = document.querySelectorAll('.item');
      const lastPictureIndex = pictureItems.length - 1;
      const currentPictureIndex = Array.from(pictureItems).indexOf(current);
      const buttonLeft = document.querySelector('.button-left');
      const buttonRight = document.querySelector('.button-right');
      const firstPictureIndex = 0;
      const secondPictureIndex = 1;


      if (currentPictureIndex === lastPictureIndex) {
        buttonLeft.classList.add('end');
        buttonRight.classList.add('end');
      } else {
        buttonLeft.classList.remove('end');
        buttonRight.classList.remove('end');
      }

      if (currentPictureIndex === firstPictureIndex) {
        buttonRight.classList.add('debut');
      } else {
        buttonRight.classList.remove('debut');
      }

      if (currentPictureIndex === secondPictureIndex) {
        buttonLeft.classList.add('goHome');
      } else {
        buttonLeft.classList.remove('goHome');
      }
    },
    updateCurrentFigure(index) {
      this.currentFigureIndex = index;
    },
    currentNumber() {
      const currentNumber = this.currentImageIndex;
      return currentNumber === 1 ? 1 : Math.max(1, currentNumber);
    },
    activateLeftKey() {
      const current = document.querySelector('.current');
      const leftDevice = document.querySelector('.left--device');

      leftDevice.style.color = '#111';
      setTimeout(() => {
        leftDevice.style.color = '';
      }, 250);

      if (current.previousElementSibling) {
        current.previousElementSibling.classList.add('current');
        current.classList.remove('current');
        this.fadeInToCurrent();
        if (window.innerWidth <= 1024) {
          this.fadeInToCurrent();
        }
      }
      this.endArrow();
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      }

      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag('event', 'prev_all_pages', {
        'event_category': 'Click',
        'event_label': 'click_to_button_left_all_pages'
      });

      if (this.$route.name === 'FictionDiurne') {
        gtag('event', 'prev_fiction_diurne', {
          'event_category': 'Click',
          'event_label': 'click_to_button_left_fiction_diurne'
        });
      }

      if (this.$route.name === 'PuzzlePage') {
        gtag('event', 'prev_puzzle', {
          'event_category': 'Click',
          'event_label': 'click_to_button_left_puzzle'
        });
      }

      if (this.$route.name === 'PetiteChoseSansValeur') {
        gtag('event', 'prev_petite_chose_sans_valeur', {
          'event_category': 'Click',
          'event_label': 'click_to_button_left_petite_chose_sans_valeur'
        });
      }
      
    },
    activateRightKey() {
      const current = document.querySelector('.current');
      const rightDevice = document.querySelector('.right--device');

      rightDevice.style.color = '#111';
      setTimeout(() => {
        rightDevice.style.color = '';
      }, 250);

      if (current.nextElementSibling) {
        current.nextElementSibling.classList.add('current');
        current.classList.remove('current');
        this.fadeInToCurrent();
        if (window.innerWidth <= 1024) {
          this.fadeInToCurrent();
        }
      }
      this.endArrow();

      if (this.currentImageIndex < this.images.length - 0) {
        this.currentImageIndex++;
      }

      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag('event', 'next_all_pages', {
        'event_category': 'Click',
        'event_label': 'click_to_button_right_all_pages'
      });

      if (this.$route.name === 'FictionDiurne') {
        gtag('event', 'next_fiction_diurne', {
          'event_category': 'Click',
          'event_label': 'click_to_button_right_fiction_diurne'
        });
      }

      if (this.$route.name === 'PetiteChoseSansValeur') {
        gtag('event', 'next_petite_chose_sans_valeur', {
          'event_category': 'Click',
          'event_label': 'click_to_button_right_petite_chose_sans_valeur'
        });
      }

      if (this.$route.name === 'PuzzlePage') {
        gtag('event', 'next_puzzle', {
          'event_category': 'Click',
          'event_label': 'click_to_button_right_puzzle'
        });
      }

    },
    displayImage(index) {
      this.currentImageIndex = index;
      this.currentNumber = this.currentImageIndex + 1;
    },
    handleImageClick(index) {
      const current = document.querySelector('.current');
      const indexGallery = document.querySelector('.index-gallery');
      const indexGalleryOverlay = document.querySelector('.index-gallery-overlay');
      const indexGalleryOverlayEdition = document.querySelector('.index-gallery-overlay.fromEdition');
      const figure = document.querySelector('figure.picture.item');
      const MG = document.querySelector('.main--gallery');
      const GV = document.querySelector('.grid-view');
      const menuIndex = document.querySelector('.menu--index.mobile');
      const buttonRight = document.querySelector('.button-scroll .button-right');
      const buttonLeft = document.querySelector('.button-scroll .button-left');
      const buttonScroll = document.querySelector('.button-scroll');
      const GridViewImg = document.querySelectorAll('.picture');
      const cursor = document.querySelector(".cursor");
      const follower = document.querySelector(".cursor-follower");
      const home = document.querySelector('.titre--site');
      const transImg = document.querySelectorAll('figure.item');
      const loader = document.querySelector('.loader--gif');
      const menuEdition = document.querySelector('.menu--edition');
      const infoText = document.querySelector('.back--text');

      if (index === this.images.length - 1) {
        GV.scrollTo({ top: 0 });
        buttonLeft.classList.add('end');
        buttonRight.classList.remove('debut');
        MG.classList.remove('grid-view');
        current.classList.remove('current');
        cursor.classList.remove('active5-2');
        follower.classList.remove('active4-2');
        cursor.classList.remove("active5-white");
        follower.classList.remove("cursor--index");
        indexGallery.classList.remove('opacity-zero');
        figure.classList.add('current');
        indexGallery.classList.add('blanche');
        indexGalleryOverlay.classList.add('opacity-zero');
        indexGalleryOverlayEdition.classList.add('opacity-zero');
        menuIndex.classList.add('bottom-disappear');
        cursor.classList.add('cursor-blanche');
        follower.classList.add('cursor-blanche2');
      } else {
        GV.scrollTo({ top: 0 });
        MG.classList.remove('grid-view');
        current.classList.remove('current');
        figure.classList.add('current');
        buttonLeft.classList.remove('debut');
        indexGallery.classList.remove('opacity-zero');
        indexGallery.classList.add('blanche');
        indexGalleryOverlay.classList.add('opacity-zero');
        indexGalleryOverlayEdition.classList.add('opacity-zero');
        menuIndex.classList.add('bottom-disappear');
        buttonRight.classList.remove('debut');
        cursor.classList.remove('active5-2');
        follower.classList.remove('active4-2');
        cursor.classList.remove("active5-white");
        follower.classList.remove("cursor--index");
        cursor.classList.add('cursor-blanche');
        follower.classList.add('cursor-blanche2');
      }
      loader.classList.add('overlay--loader');
      infoText.style.display = "none";
      home.classList.remove('opacity-zero');
      buttonScroll.classList.remove('opacity-zero');
      menuEdition.classList.remove('opacity-zero');

      const images = document.querySelectorAll('.main--gallery .item');
      images.forEach(function (image) {
        image.classList.remove('current');
      });

      const figure2 = event.target.closest('figure');
      if (figure2) {
        figure2.classList.add('current');
        figure2.style.opacity = "0 !important";
      }

      setTimeout(() => {
        this.fadeInToCurrent();
        GridViewImg.forEach(img => img.classList.remove('fade-in'));
        transImg.forEach(img => img.style.transition = "filter 0s ease, opacity 0.7s ease");
      }, 800);

      setTimeout(() => {
        loader.classList.remove('overlay--loader');
      }, 1500);

      
      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag('event', 'index_to_img_all_pages', {
        'event_category': 'Click',
        'event_label': 'click_to_show_img_all_pages'
      });

      if (this.$route.name === 'FictionDiurne') {
        gtag('event', 'index_to_img_fiction_diurne', {
          'event_category': 'Click',
          'event_label': 'click_to_show_img_fiction_diurne'
        });
      }

      if (this.$route.name === 'PetiteChoseSansValeur') {
        gtag('event', 'index_to_img_petite_chose_sans_valeur', {
          'event_category': 'Click',
          'event_label': 'click_to_show_img_petite_chose_sans_valeur'
        });
      }

      if (this.$route.name === 'PuzzlePage') {
        gtag('event', 'index_to_img_puzzle', {
          'event_category': 'Click',
          'event_label': 'click_to_show_img_puzzle'
        });
      }
    },
    onKeydown(event) {
      if (event.keyCode === 37) {
        this.activateLeftKey();
      } else if (event.keyCode === 39) {
        this.activateRightKey();
      }
    },
    showEmailDiv(event) {
      const emailDiv = document.getElementById("email");
      const cursor = document.querySelector(".cursor");
      const follower = document.querySelector(".cursor-follower");

      emailDiv.style.opacity = "1";
      emailDiv.style.pointerEvents = "auto";

      emailDiv.style.top = event.clientY + "px";
      emailDiv.style.left = event.clientX + "px";

      emailDiv.addEventListener('mouseover', function () {
        emailDiv.style.opacity = "1";
        emailDiv.style.pointerEvents = "auto";
        cursor.classList.add('no-cursor');
        follower.classList.add('no-cursor');
      })

      emailDiv.addEventListener('mouseleave', function () {
        emailDiv.style.opacity = "0";
        emailDiv.style.pointerEvents = "none";
        cursor.classList.remove('no-cursor');
        follower.classList.remove('no-cursor');
      })
    },
    //EDITION MENU
    showInfoEdition() {
      const figcaptions = document.querySelectorAll('figcaption');
      const infoEdition = document.querySelector('.info--edition');

      figcaptions.forEach(function (figcaption) {
        figcaption.classList.toggle('showFigcaption');
      });

      infoEdition.classList.toggle('focus--edition');
    },
    fadeInToCurrent() {
      const gallery = document.querySelector('.main--gallery');
      const current = document.querySelector('.current');
      const scrollAmount = current.offsetLeft - gallery.offsetWidth / 2 + current.offsetWidth / 2;
      gallery.scroll({
        left: scrollAmount,
        behavior: 'instant'
      });
    },
    zoomEdition() {
      const zoomEdition = document.querySelector('.zoom--edition');
      const images = document.querySelectorAll('figure');
      const background = document.querySelector('.galleryBG');
      const index = document.querySelector('.index-gallery.documentTitle');
      const menuEdition = document.querySelector('.menu--edition');
      const buttonLeft = document.querySelector('.button-left');
      const buttonRight = document.querySelector('.button-right');
      const gallery = document.querySelector('.main--gallery');
      const loader = document.querySelector('.loader--gif');
      const figcaptions = document.querySelectorAll('figcaption');

      buttonLeft.classList.remove('yellow-arrow');
      buttonRight.classList.remove('yellow-arrow');

      zoomEdition.classList.toggle('focus--edition');
      index.classList.toggle('black111');
      menuEdition.classList.toggle('black111');
      buttonLeft.classList.toggle('black-zoom-arrow');
      buttonRight.classList.toggle('black-zoom-arrow');

      gallery.classList.add('invisible');
      gallery.classList.toggle('fade--gallery');
      loader.classList.add('overlay--loader');

      setTimeout(() => {
        images.forEach(function (image) {
          image.classList.toggle('zoom--img');
          image.classList.add('opacity-zero');
        });
        figcaptions.forEach(function (figcaption) {
          figcaption.classList.toggle('black111');
        });
      }, 450);

      setTimeout(() => {
        background.classList.toggle('zoomBG');
        this.fadeInToCurrent();
      }, 950);

      setTimeout(() => {
        loader.classList.remove('overlay--loader');
      }, 1700);

      setTimeout(() => {
        images.forEach(function (image) {
          image.classList.remove('opacity-zero');
        });
        gallery.classList.remove('invisible');
      }, 1950);
    },
    zoomImg(index) {
      for (let i = 0; i < this.images.length; i++) {
        this.$refs['pictureItem' + i][0].classList.remove('current');
      }
      this.$refs['pictureItem' + index][0].classList.add('current');
      this.currentIndex = index;
      this.$refs['pictureItem' + index][0].addEventListener('click', () => {
        setTimeout(() => {
          this.zoomEdition();
        }, 150);
      });
    },
    navigateToHome() {
      const self = this;
      const background = document.querySelector('.galleryBG');
      const loader = document.querySelector('.loader--gif');
      const loaderH1 = document.querySelector('.loader--gif h1');
      const cursor = document.querySelector(".cursor");
      const follower = document.querySelector(".cursor-follower");
      const topHome = document.querySelector('.titre--site');

      if (window.innerWidth > 1024) {
        loaderH1.style.display = 'none';
        loader.classList.add('overlay--loader');
        background.style.backgroundColor = "black";
        background.style.transition = "all 0.5s ease";
        cursor.classList.remove('cursor-blanche');
        follower.classList.remove('cursor-blanche2');
        topHome.classList.add('opacity-zero');

        setTimeout(function () {
          self.$router.push('/');
        }, 700);
      }

      if (window.innerWidth <= 1024) {
        loaderH1.style.display = 'none';
        loader.classList.add('overlay--loader');
        background.style.backgroundColor = "black";
        background.style.transition = "all 0.5s ease";
        cursor.classList.remove('cursor-blanche');
        follower.classList.remove('cursor-blanche2');

        setTimeout(function () {
          self.$router.push('/');
        }, 400);
      }
    },
    showIndexEdition() {
      const menuEdition = document.querySelector('.menu--edition');
      const GridView = document.querySelector('.main--gallery');
      const cursor = document.querySelector(".cursor");
      const follower = document.querySelector(".cursor-follower");
      // const transImg = document.querySelectorAll('figure.item');
      const buttonScroll = document.querySelector('.button-scroll');
      const indexGalleryOverlay = document.querySelector('.menu--index.desktop .index-gallery-overlay.fromEdition');
      const menuIndexOverlay = document.querySelector('.menu--index.desktop');
      const loader = document.querySelector('.loader--gif');
      const titreSite = document.querySelector('.titre--site');

      loader.classList.add('overlay--loader');

      cursor.classList.remove("active5-white");
      cursor.classList.remove("cursor-blanche");
      follower.classList.remove("cursor-blanche2");

      menuEdition.classList.add('opacity-zero');
      follower.classList.add("cursor--index");
      titreSite.classList.add('opacity-zero');
      buttonScroll.classList.add('opacity-zero');

      setTimeout(() => {
        GridView.classList.toggle("grid-view");
        // transImg.forEach(img => img.style.transition = "filter 0.5s ease, opacity 0.7s ease");
      }, 50);

      setTimeout(() => {
        this.fadeInToCurrent();
      }, 1000);

      setTimeout(() => {
        loader.classList.remove('overlay--loader');
      }, 1500);

      setTimeout(() => {
        menuIndexOverlay.classList.remove('opacity-zero');
        indexGalleryOverlay.classList.remove('opacity-zero');
      }, 1600);
    },
    handleTouchStart(event) {
      function gtag() {
        window.dataLayer.push(arguments);
      }

      if (event.touches.length === 2) {
        this.isZooming = true; // Set flag to indicate zooming
        this.clearSwipeTimer(); // Clear any existing swipe timer
        gtag('event', 'mobile_zoom_all_pages', {
          'event_category': 'Click',
          'event_label': 'mobile_zoom_in_all_pages'
        });

        if (this.$route.name === 'HomePage') {
          gtag('event', 'mobile_zoom_homepage', {
            'event_category': 'Click',
            'event_label': 'mobile_zoom_in_homepage'
          });
        }

        if (this.$route.name === 'InfoPage') {
          gtag('event', 'mobile_zoom_info', {
            'event_category': 'Click',
            'event_label': 'mobile_zoom_in_info'
          });
        }

        if (this.$route.name === 'ChampDeRiviere') {
          gtag('event', 'mobile_zoom_champ_de_riviere', {
            'event_category': 'Click',
            'event_label': 'mobile_zoom_in_champ_de_riviere'
          });
        }

        if (this.$route.name === 'PetiteChoseSansValeur') {
          gtag('event', 'mobile_zoom_petite_chose_sans_valeur', {
            'event_category': 'Click',
            'event_label': 'mobile_zoom_in_petite_chose_sans_valeur'
          });
        }
  
        if (this.$route.name === 'PuzzlePage') {
          gtag('event', 'mobile_zoom_puzzle', {
            'event_category': 'Click',
            'event_label': 'mobile_zoom_in_puzzle'
          });
        }
  
        if (this.$route.name === 'FictionDiurne') {
          gtag('event', 'mobile_zoom_fiction_diurne', {
            'event_category': 'Click',
            'event_label': 'mobile_zoom_in_fiction_diurne'
          });
        }
      } else {
        this.startX = event.touches[0].clientX;
        this.swipeHandled = false; // Reset the flag for swipe gesture
      }
    },
    handleGestureChange(event) {
      if (event.scale !== 1) {
        // Zooming detected, do not prevent default behavior for zoom
        this.isZooming = true; // Set flag to indicate zooming
        this.clearSwipeTimer(); // Clear any existing swipe timer
        return;
      }
    },
    handleTouchMove(event) {
      if (this.isZooming) {
        return; // If zooming, do not handle swipe
      }

      if (event.touches.length === 1 && !this.swipeHandled) {
        const distanceX = event.touches[0].clientX - this.startX;

        if (distanceX < -50) {
          this.activateRightKey();
          this.swipeHandled = true;
        }
        if (distanceX > 50) {
          this.activateLeftKey();
          this.swipeHandled = true;
        }
      }
    },
    handleTouchEnd() {
      this.startX = null;

      if (this.isZooming) {
        this.setSwipeTimer(); // Set a timer to allow swipes after zooming
      }
    },
    setSwipeTimer() {
      this.swipeTimer = setTimeout(() => {
        this.isZooming = false; // Reset flag after a delay
      }, 1000); // Adjust the delay as needed (in milliseconds)
    },
    clearSwipeTimer() {
      clearTimeout(this.swipeTimer);
    },
    handleResize() {
      setTimeout(() => {
        this.fadeInToCurrent();
      }, 500);
    }
  },
  mounted() {
    const cursor = document.querySelector(".cursor");
    const buttonScroll = document.querySelector(".button-scroll");
    const current = document.querySelector(".main--gallery");
    const follower = document.querySelector(".cursor-follower");
    const buttonLeft = document.querySelector('.button-scroll .button-left');
    const buttonRight = document.querySelector('.button-scroll .button-right');
    const indexGallery = document.querySelector('.index-gallery');
    let GridView = document.querySelector('.main--gallery');
    let indexGalleryOverlayMobile = document.querySelector('.menu--index.mobile .index-gallery-overlay');
    let menuIndex = document.querySelector('.menu--index.mobile');
    const transImg = document.querySelectorAll('figure.item');
    const deviceMenu = document.querySelector('.device--menu');
    const description = document.querySelector('.description.item');
    const documentTitle = document.querySelector('.index-gallery.documentTitle');
    const counter = document.querySelector('.figure-counter.pages');
    const titreSiteMobile = document.querySelector('.titre--site img');
    const SiteMobile = document.querySelector('.home--mobile');
    const indexG = document.querySelector('.index-gallery');
    const flecheOverlay = document.querySelector('.index-gallery-overlay');

    setTimeout(() => {
      this.activateRightKey();
    }, 1200);

    window.addEventListener('keydown', this.onKeydown);
    window.addEventListener('resize', this.handleResize);

    document.querySelector('.loader--gif').classList.add('style-one');
    document.querySelector('.documentTitle').classList.add('riviere');

    setTimeout(function () {
      document.querySelector('.titre--site').classList.add('blanche');
      document.body.classList.add('grainBG');
      document.body.style.backgroundColor = '';
      document.body.classList.remove('antiqueBG');
      cursor.classList.remove('info');
      follower.classList.remove('info');
    }, 50);

    flecheOverlay.style.color = "#111";

    flecheOverlay.addEventListener("mouseover", function () {
      flecheOverlay.style.color = "black";
    });

    flecheOverlay.addEventListener("mouseout", function () {
      flecheOverlay.style.color = "#111";
    });

    flecheOverlay.addEventListener("click", function () {
      indexG.classList.add('blanche');
    });

    document.querySelector('.figure-counter').addEventListener('mouseenter', function () {
      document.querySelectorAll('.item.picture').forEach(function (figure) {
        figure.style.opacity = '0';
      });
      document.querySelector('.item.picture.current').style.opacity = '1';
    });

    document.querySelector('.figure-counter').addEventListener('mouseleave', function () {
      document.querySelectorAll('.item.picture').forEach(function (figure) {
        figure.style.opacity = '1';
      });
      document.querySelector('.item.picture.current').style.opacity = '1';
    });

    // ONLOAD + ADD CURRENT TO DESCRIPTION ITEM
    this.$el.querySelector('.description.item').classList.add('current');
    setTimeout(() => {
      this.$el.querySelector('.picture.item').classList.remove('current');
    }, 0);

    // ONLOAD + CHANGE CURSOR TO BLACK AFTER ROUTE
    document.body.classList.remove('wheatBG');
    document.body.classList.remove('grainBG');
    document.body.classList.remove('zoomBG');
    document.body.classList.remove('griseBG');
    document.body.classList.remove('blancheBG');
    document.body.classList.remove('blackBG');
    document.body.classList.remove('antiqueBG');
    document.body.classList.remove('whiteBG');
    document.body.classList.remove('neutreBG');
    document.body.classList.remove('puzzleBG');
    document.body.classList.add('galleryBG');
    setTimeout(() => {
      document.body.classList.add('galleryBG');
      cursor.classList.remove('active7');
      follower.classList.remove('active7');
      cursor.classList.add('cursor-blanche');
      follower.classList.add('cursor-blanche2');
    }, 1);
    buttonLeft.classList.add('black-arrow');
    buttonRight.classList.add('black-arrow');

    indexGalleryOverlayMobile.addEventListener("click", function () {
      menuIndex.classList.add('bottom-disappear');
      indexGallery.classList.remove('opacity-zero');
      indexGallery.classList.add('blanche');
      GridView.scrollTo({ top: 0 });
      setTimeout(function () {
        GridView.classList.remove('grid-view');
      }, 5);
    });

    // NAVIGATE TO HOME
    const observer2 = new MutationObserver((mutations) => {
      console.log(mutations);
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          if (description.classList.contains('current')) {
            // document.querySelector('.titre--site').click();
            this.activateRightKey();
          }
        }
      });
    });
    observer2.observe(description, { attributes: true });

    // OPACITY GRID VIEW
    const observerView = new MutationObserver((mutationsView) => {
      console.log(mutationsView);
      mutationsView.forEach((mutationV) => {
        if (mutationV.type === 'attributes' && mutationV.attributeName === 'class') {
          if (GridView.classList.contains('grid-view')) {
            deviceMenu.classList.add('opacity-zero');
            counter.classList.add('opacity-zero');
            titreSiteMobile.classList.add('opacity-zero');
            SiteMobile.classList.add('opacity-zero');
          } else {
            setTimeout(() => {
              deviceMenu.classList.remove('opacity-zero');
              counter.classList.remove('opacity-zero');
              titreSiteMobile.classList.remove('opacity-zero');
              SiteMobile.classList.remove('opacity-zero');
            }, 1500);
          }
        }
      });
    });
    observerView.observe(GridView, { attributes: true });


    // SCROLL TO THE CENTER OF THE ITEM
    const gallery = document.querySelector('.scrolling');
    let scrollToCurrent = () => {
      const current = document.querySelector('.current');
      const scrollAmount = current.offsetLeft - gallery.offsetWidth / 2 + current.offsetWidth / 2;
      const behavior = gallery.classList.contains('fade--gallery') ? 'instant' : 'smooth';
      gallery.scroll({
        left: scrollAmount,
        behavior: behavior
      });
      this.debutArrow();
    }
    // loop through the figure elements and add a click event listener
    for (let i = 0; i < this.images.length; i++) {
      this.$refs['pictureItem' + i][0].addEventListener('click', () => {
        this.handleImageClick(event, i);
        this.displayImage(i + 1);
      })
    }

    const indexOneMobile = document.querySelector('.back--text');
    indexOneMobile.addEventListener('click', () => {
      this.currentImageIndex = 1;
    });

    // SCROLLMOUSE EVENTS
    let scrollToCurrentMouse = () => {
      const current = document.querySelector('.current');
      const scrollAmount = current.offsetLeft - gallery.offsetWidth / 2 + current.offsetWidth / 2;
      gallery.scroll({
        left: scrollAmount,
        behavior: 'smooth'
      });
      this.endArrow();
    }

    //icici
    buttonScroll.addEventListener('wheel', function (event) {
      if (event.deltaY < 0) {
        const event = new KeyboardEvent('keydown', {
          keyCode: 37,
          key: 'ArrowLeft',
          code: 'ArrowLeft',
        });

        window.dispatchEvent(event);
      } else {
        const event = new KeyboardEvent('keydown', {
          keyCode: 39,
          key: 'ArrowRight',
          code: 'ArrowRight',
        });
        window.dispatchEvent(event);
      }
      scrollToCurrentMouse();
    });

    current.addEventListener('wheel', function (event) {
      if (event.deltaY < 0) {
        const event = new KeyboardEvent('keydown', {
          keyCode: 37,
          key: 'ArrowLeft',
          code: 'ArrowLeft',
        });

        window.dispatchEvent(event);
      } else {
        const event = new KeyboardEvent('keydown', {
          keyCode: 39,
          key: 'ArrowRight',
          code: 'ArrowRight',
        });
        window.dispatchEvent(event);
      }
      scrollToCurrentMouse();
    });

    let fadingToCurrent = () => {
      const gallery = document.querySelector('.main--gallery');
      const current = document.querySelector('.current');
      const scrollAmount = current.offsetLeft - gallery.offsetWidth / 2 + current.offsetWidth / 2;
      gallery.scroll({
        left: scrollAmount,
        behavior: 'instant'
      });
    }

    // THUMBS CLICK EVENT SCROLL TO CURRENT ITEM
    const indexBtn = document.querySelector('.index-gallery');
    indexBtn.addEventListener('click', function () {
      document.querySelector('figure.item.first').classList.add('current');
      document.querySelector('.picture.item').classList.remove('current');
      scrollToCurrent();
    });

    documentTitle.addEventListener('click', () => {
      setTimeout(() => {
        this.currentImageIndex = 1;
      }, 800);
      transImg.forEach((img) => img.classList.remove('current'));
      transImg.forEach((img) => img.style.opacity = '0');
      setTimeout(() => {
        document.querySelector('figure.item.first').classList.add('current');
        transImg.forEach((img) => img.style.opacity = '');
        fadingToCurrent();
      }, 800);
    });

    // CUSTOM CURSOR 1
    const links2 = document.querySelectorAll(".link2");
    links2.forEach(link2 => {
      link2.addEventListener("mouseenter", function () {
        cursor.classList.add("active3");
        follower.classList.add("active3");
      });
      link2.addEventListener("mouseleave", function () {
        cursor.classList.remove("active3");
        follower.classList.remove("active3");
      });
    });

    // CUSTOM CURSOR 1
    const linksText = document.querySelectorAll(".linkText");
    linksText.forEach(link2 => {
      link2.addEventListener("mouseenter", function () {
        cursor.classList.add("active-arrow-text");
        follower.classList.add("active-arrow-text");
      });
      link2.addEventListener("mouseleave", function () {
        cursor.classList.remove("active-arrow-text");
        follower.classList.remove("active-arrow-text");
      });
    });

    // CUSTOM CURSOR 1
    const linksIndex = document.querySelectorAll(".linkIndex");
    linksIndex.forEach(link4 => {
      link4.addEventListener("mouseenter", function () {
        cursor.classList.add("active6-2");
        follower.classList.add("active3");
      });
      link4.addEventListener("mouseleave", function () {
        cursor.classList.remove("active6-2");
        follower.classList.remove("active3");
      });
    });

    // CUSTOM CURSOR 1
    const links2Arrow = document.querySelectorAll(".link2-arrow");
    links2Arrow.forEach(link2Arrow => {
      link2Arrow.addEventListener("mouseenter", function () {
        cursor.classList.add("active3-Arrow");
        follower.classList.add("active3");
      });
      link2Arrow.addEventListener("mouseleave", function () {
        cursor.classList.remove("active3-Arrow");
        follower.classList.remove("active3");
      });
    });

    // CUSTOM CURSOR 1
    const links4 = document.querySelectorAll(".link4");
    links4.forEach(link2 => {
      link2.addEventListener("mouseenter", function () {
        cursor.classList.add("active-link4-2");
        follower.classList.add("active-link4");
      });
      link2.addEventListener("mouseleave", function () {
        cursor.classList.remove("active-link4-2");
        follower.classList.remove("active-link4");
      });
    });

    // CUSTOM CURSOR 2
    const arrowUps = document.querySelectorAll(".titre--site");
    arrowUps.forEach(arrowUp => {
      arrowUp.addEventListener("mouseenter", function () {
        cursor.classList.add("active6-2");
        follower.classList.add("active3");
      });
      arrowUp.addEventListener("mouseleave", function () {
        cursor.classList.remove("active6-2");
        follower.classList.remove("active3");
      });
      arrowUp.addEventListener("click", function () {
        cursor.classList.remove("active6-2");
        follower.classList.remove("active3");
        cursor.classList.remove("cursor-blanche");
        follower.classList.remove("cursor-blanche2");
      });
    });

    // CUSTOM CURSOR 4
    const arrows2 = document.querySelectorAll(".button-left");
    arrows2.forEach(arrow2 => {
      arrow2.addEventListener("mouseenter", function () {
        cursor.classList.add("active5-white-2");
        follower.classList.add("active3");
      });
      arrow2.addEventListener("mouseleave", function () {
        cursor.classList.remove("active5-white-2");
        follower.classList.remove("active3");
      });
    });


    const arrows3 = document.querySelectorAll(".button-right");
    arrows3.forEach(arrow3 => {
      arrow3.addEventListener("mouseenter", function () {
        cursor.classList.add("active5-white-2");
        follower.classList.add("active3");
      });
      arrow3.addEventListener("mouseleave", function () {
        cursor.classList.remove("active5-white-2");
        follower.classList.remove("active3");
      });
    });

    // CUSTOM CURSOR 5
    const thumbLinks = document.querySelectorAll(".image-item");
    thumbLinks.forEach(thumb => {
      thumb.addEventListener("mouseenter", function () {
        cursor.classList.add("active7");
        follower.classList.add("active7");
      });
      thumb.addEventListener("mouseleave", function () {
        cursor.classList.remove("active7");
        follower.classList.remove("active7");
      });
    });

    // CUSTOM CURSOR 5
    const redpointer = document.querySelectorAll(".red-pointer");
    redpointer.forEach(red => {
      red.addEventListener("mouseenter", function () {
        cursor.classList.add("active7");
        follower.classList.add("active7");
      });
      red.addEventListener("mouseleave", function () {
        cursor.classList.remove("active7");
        follower.classList.remove("active7");
      });
    });


    // GTAG
    const lastItemAll = document.querySelector('.lastItem');

    function gtag() {
      window.dataLayer.push(arguments);
    }

    if (lastItemAll.classList.contains("current")) {
        // Replace 'track-category' and 'track-action' with your own values
        gtag('event', 'reach_end_pages', {
          'event_category': 'Click',
          'event_label': 'reach_end_pages'
        });
    }
  },

  unmounted() {
    const background = document.querySelector('.galleryBG');
    const cursor = document.querySelector(".cursor");
    const follower = document.querySelector(".cursor-follower");
    const homeSite = document.querySelector('.titre--site');

    window.removeEventListener('keydown', this.onKeydown);

    background.style.backgroundColor = "#111";
    setTimeout(function () {
      document.body.classList.remove('galleryBG');
    }, 1800);

    homeSite.style.opacity = "0";
    homeSite.style.transition = "all 1s ease";

    cursor.classList.remove('active5-white');
    cursor.classList.remove('cursor-blanche');
    follower.classList.remove('cursor-blanche2');
    cursor.classList.remove('active7');
    follower.classList.remove('active7');
  }
}