<template>
  <div class="move" @contextmenu.prevent="showEmailDiv">

    <div class="main--gallery theme--one" @touchstart="handleTouchStart" @gesturechange="handleGestureChange"
      @touchmove="handleTouchMove" @touchend="handleTouchEnd" ref="elementToDisableSwipeAndZoom">

      <div ref="descriptionFirst" class="dark--grain description item current" @click.stop="disableClick">

        <div class="bottom-right">
          <div class="main--img one">
            <div class="layer--black"></div>
            <img ref="pictureItem" src="../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_main.jpg" alt="" />
          </div>
        </div>
      </div>

      <figure v-motion="fadeText" v-for="(image, index) in images"
        :key="index === images.length - 1 ? 'lastPicture' : index" :ref="'pictureItem' + index"
        :class="['picture FADEIN red-pointer', 'item antique center-self', image.class, { 'lastItem': index === images.length - 1 }]">
        <div :class="['image-container red-pointer gallery--one', image.classContainer]">
          <div :class="['image-item leg-left', image.classDiv1]">
            <img v-if="image.src" :src="image.src" :class="image.class1" :alt="altText" />
          </div>
          <div :class="['image-item leg-right', image.classDiv2]" v-if="image.hasSecondImage">
            <img :src="image.src2" :class="image.class2" @click="zoomImg(index)" :alt="altText" />
          </div>
          <div :class="['image-item leg-third', image.classDiv3]" v-if="image.hasThirdImage">
            <img :src="image.src3" :class="image.class3" @click="zoomImg(index)" :alt="altText" />
          </div>
          <div :class="['image-item leg-fourth', image.classDiv4]" v-if="image.hasFourthImage">
            <img :src="image.src4" :class="image.class4" @click="zoomImg(index)" :alt="altText" />
          </div>
          <div :key="'textSection' + index" v-if="image.hasText" :class="['centered-text', image.classText]">
            <h1 v-html="image.text"></h1>
          </div>
          <div :class="['caption', image.classCaption]">
            <figcaption v-html="image.title" :class="['desktop', image.classText]"></figcaption>
          </div>
        </div>
        <figcaption v-html="image.title" :class="['mobile', image.classText]"></figcaption>
      </figure>

    </div>
    <MenuGallery />

    <div class="figure-counter pages opacity-zero" style="pointer-events: none" v-motion="fade4">
      <span class="current-counter">{{ currentImageIndex }}</span><span class="slash-counter"> — </span> <span
        class="total-counter">{{ totalFigures }}</span>
    </div>

    <div class="red-pointer loader--gif">
      <h1>{{ documentTitle }}</h1>
      <div class="img--home">
        <div class="layer--black"></div>
        <img :key="currentImage" src="../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_main.jpg" />
      </div>
    </div>

  </div>
</template>

<script>
import GalleryScript from '../../directives/GalleryScript.js';
import GalleryScriptObserver from '../../directives/GalleryScriptObserver.js';
import { fade2, fadeText, fade3, fade4 } from '../../directives/AnimationHome.js';

import MenuGallery from "../menu/MenuGallery.vue";
// import { pageview } from 'vue-gtag'

export default {
  name: 'FictionDiurne',
  components: { MenuGallery },
  mixins: [GalleryScript, GalleryScriptObserver],
  data() {
    return {
      documentTitle: document.title,
      currentImageIndex: 0,
      currentIndex: null,
      fade2, fadeText, fade3, fade4,
      altText: "Jason Pumo, Fiction Diurne, photographie narrative, réel, couleur, Arles, France, mouvement, Terre, Soleil, Lune, temps, art, artiste, argentique, analog, film",
      images: [

        {
          hasText: true, src: '', title: '', class: 'cartel-text xl-pad-left xl-pad-right first red-pointer', classText: 'mobile-fit',
          text: `<span class="titre">Fiction Diurne</span><span class="annee">— &nbsp; 2021</span>
          <div class="ensemble">
            <div class="francais">
          <p>Dans un monde à l'arrêt, proche de l'embrasement,
            une journée fictive prend forme. L'itinéraire mystique du photographe se voit être rythmé par le mouvement diurne de la Terre.
            Son unique approche est celle de capturer un réel faussement figé.
            Le déplacement en voiture devient une forme de mobilité sophistiquée, à la poursuite du photographique.</p>
            </div>
            <div class="anglais">
          <p>In a world at a standstill, on the edge of conflagration, a fictive day begins.
            The photographer's mystical itinerary is punctuated by the diurnal movement of the Earth.
            His only approach is to capture a falsely frozen reality.
            Moving by car takes on a sophisticated form of mobility, in pursuit of the photographic.</p>
            </div>
            </div>
            <div class="ensemble">
            <div class="francais">
          <p>L’acte photographique s'apparente alors à une activité diurne,
            amalgamant <i>temps</i> et <i>réel</i>. Une certaine quête du saisissable et de l’insaisissable.
            C’est entre ces fragments de réel que l'essence de l’insaisissable se contient.
            Cet espace manquant – <i>qualifié de vide narratif au cinéma</i> – apparaît alors comme un temps fictif
            dans lequel le réel continue d’exister.</p>
            </div>
            <div class="anglais">
          <p>The photographic act is a kind of diurnal activity, an amalgam of <i>time</i> and <i>the real</i>.
            A quest for the graspable and the elusive. It is between these pieces of reality that the essence of the elusive is enclosed.
            This missing space -<i> defined as a narrative void in cinema </i>- appears as a fictive time in which the real still exists.</p>
            </div>
            </div>`
        },

        {
          classDiv1: 'reverse',
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/fiction-diurne-vue-1.jpg'),
          title: `
          Accrochage École Nationale Supérieur de la Photographie,<br />
          <span>Arles, 2022</span>`,
          classCaption: 'bottomCaption rightCaption'
        },

        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_4.jpg'),
          title: "Sablier, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_33.jpg'),
          title: "Sans titre, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        {
          classDiv1: 'reverse',
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_34.jpg'),
          title: "Sans titre, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_3.jpg'),
          title: "Restaurant, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_8.jpg'),
          title: "Soleil, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        {
          classDiv1: 'reverse',
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_10.jpg'),
          title: "Duel, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/fiction-diurne-vue-3.jpg'),
          title: `
          Accrochage École Nationale Supérieur de la Photographie,<br />
          <span>Arles, 2022</span>`,
          classCaption: 'bottomCaption leftCaption'
        },

        {
          classDiv1: 'reverse',
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_11.jpg'),
          title: "Diurne, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        {
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_24.jpg'),
          src2: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_24-2.jpg'),
          class1: 'large-view s-pad-right',
          class2: 'large-view s-pad-left',
          classDiv2: 'height-mobile center-self',
          classDiv1: 'height-mobile leg1',
          classTitre2: 'legende-single',
          hasSecondImage: true,
          title: "Lune, <span>2021</span>"
        },

        {
          classDiv1: 'reverse',
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_39.jpg'),
          title: "Onze heures cinquante-neuf, <span>2021</span>"
        },

        {
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_1.jpg'),
          src2: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_7.jpg'),
          class1: 'large-view s-pad-right',
          class2: 'large-view s-pad-left',
          classDiv2: 'center-self',
          classDiv1: 'leg1',
          classTitre2: 'legende-single',
          hasSecondImage: true,
          title: "Invitations, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        {
          classDiv1: 'reverse',
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/fiction-diurne-vue-2.jpg'),
          title: `Accrochage École Nationale Supérieur de la Photographie,<br />
          <span>Arles, 2022</span>`,
          classCaption: 'bottomCaption rightCaption'
        },

        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_5.jpg'),
          title: "Gasoil, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        {
          classDiv1: 'reverse',
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_23.jpg'),
          title: "Sablier II, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_50.jpg'),
          title: "Mouvement, <span>2021</span>",
          classCaption: 'middleCaption'
        },


        {
          classDiv1: 'reverse',
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/fiction-diurne-vue-4.jpg'),
          title: `Accrochage École Nationale Supérieur de la Photographie,<br />
          <span>Arles, 2022</span>`,
          classCaption: 'bottomCaption rightCaption'
        },

        {
          classDiv1: 'reverse',
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_6.jpg'),
          title: "Incendie, <span>2017</span>",
          classCaption: 'middleCaption'
        },

        { hasText: true, src: '', title: '', class: 'cartel-text xl-pad-left xl-pad-right', classText: 'smallText', text: "J'ai quitté un monde pour un autre qui s'éteint" },

        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_36.jpg'),
          title: "Restes diurnes, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        {
          classDiv1: 'reverse',
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_40.jpg'),
          title: "Cartographie, <span>2021</span>",
        },

        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_41.jpg'),
          title: "Restes diurnes II, <span>2021</span>",
        },

        {
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_29.jpg'),
          src2: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_30.jpg'),
          class1: 'large-view s-pad-left',
          class2: 'large-view s-pad-right',
          classDiv2: 'center-self',
          classDiv1: 'leg1 reverse',
          classTitre2: 'legende-single',
          hasSecondImage: true,
          title: "Sans titre, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_28.jpg'),
          title: "Sans titre, <span>2021</span>"
        },
        {
          classDiv1: 'reverse',
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_38.jpg'),
          title: "Sans titre, <span>2021</span>"
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_42.jpg'),
          title: "Sans titre, <span>2020</span>"
        },
        {
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_32.jpg'),
          src2: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_27.jpg'),
          class1: 'medium-view s-pad-left',
          class2: 'medium-view s-pad-right',
          classDiv2: 'center-self',
          classDiv1: 'leg1 reverse',
          classTitre2: 'legende-single',
          hasSecondImage: true,
          title: "Sans titre, <span>2021</span>",
          classCaption: 'middleCaption'
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_37.jpg'),
          title: "Sans titre, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        {
          classDiv1: 'reverse',
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_9.jpg'),
          title: "Nocturne, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_35.jpg'),
          title: "Mouvement II, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        {
          classDiv1: 'reverse',
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/fiction-diurne/jason-pumo_fiction-diurne_31.jpg'),
          title: "Magnitude, <span>2021</span>",
          classCaption: 'middleCaption'
        },

        { hasText: true, src: '', title: '', class: 'cartel-text xl-pad-left xl-pad-right', classText: 'smallText', text: 'Après toutes ces errances diurnes,' },
        { hasText: true, src: '', title: '', class: 'cartel-text xl-pad-left xl-pad-right', classText: 'smallText', text: 'il ne me semble plus voguer entre différents lieux,' },
        { hasText: true, src: '', title: '', class: 'cartel-text xl-pad-left xl-pad-right', classText: 'smallText', text: 'mais dans une même temporalité.' }

      ],
    }
  },
  // setup() {
  //   const track = () => {
  //     pageview({ page_path: '/fiction-diurne' });
  //     pageview('/fiction-diurne');
  //   }
  //   return {
  //     track
  //   }
  // },
  mounted() {
    document.querySelector('.info--edition').classList.add('index-of-fiction-diurne');

    const IndexFiction = document.querySelector('.index-of-fiction-diurne');

    function gtag() {
      window.dataLayer.push(arguments);
    }

    IndexFiction.addEventListener('click', () => {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'index_fiction_diurne', {
        'event_category': 'Click',
        'event_label': 'click_on_index_fiction_diurne'
      });
    });

    const indexMobile = document.querySelector('.home--mobile');
    indexMobile.addEventListener('click', () => {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'index_petite_fiction_diurne', {
        'event_category': 'Click',
        'event_label': 'click_on_index_petite_fiction_diurne'
      });
    });

    const lastItem = document.querySelector('.lastItem');

    if (lastItem.classList.contains("current")) {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'reach_end_fiction_diurne', {
        'event_category': 'Click',
        'event_label': 'reach_end_fiction_diurne'
      });
    }
  },
  unmounted() {
    document.querySelector('.info--edition').classList.remove('index-of-fiction-diurne');
  }
}
</script>