export const fadeOverlay = {
    initial: { opacity: 0 },
    enter: {
        opacity: 0.333,
        transition: {
            type: 'spring',
            duration: 4000,
            delay: 1500,
        },
    }
}

export const headerAnim = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 3500,
            delay: 500,
        },
    }
}

export const headerArchives = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 3000,
            delay: 800,
        },
    }
}

export const headerSlash = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 3000,
            delay: 1100,
        },
    }
}

export const headerInfo = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 3000,
            delay: 1400,
        },
    }
}

export const fade1 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
        },
    }
}

export const fade2 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 6000,
            delay: 50,
        },
    }
}

export const fadeText = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 1500,
            delay: 600,
        },
    }
}

export const fade3 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 1200,
        },
    }
}

export const fade4 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 1600,
        },
    }
}

export const fade5 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 2000,
        },
    }
}

export const fade6 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 2400,
        },
    }
}

export const fade7 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 2800,
        },
    }
}

export const fade8 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 3200,
        },
    }
}

export const fade9 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 3600,
        },
    }
}

export const fade10 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 4000,
        },
    }
}

export const fade11 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 4400,
        },
    }
}

export const fade12 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 4800,
        },
    }
}

export const fade13 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 5200,
        },
    }
}

export const fade14 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 5600,
        },
    }
}

export const fade15 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 6000,
        },
    }
}

export const fade16 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 6400,
        },
    }
}

export const fade17 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 6800,
        },
    }
}

export const fade18 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 7200,
        },
    }
}

export const fade19 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 7600,
        },
    }
}

export const fade20 = {
    initial: { opacity: 0 },
    enter: {
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 2500,
            delay: 8000,
        },
    }
}

export const animHeader = {
    initial: {
        y: -20,
        opacity: 0
    },
    enter: {
        y: 0,
        opacity: 1,
        transition: {
            stiffness: 75,
            damping: 15,
            mass: 5,
        },
    }
}

export const animInfo = {
    initial: {
        y: 20,
        opacity: 0
    },
    enter: {
        y: 0,
        opacity: 1,
        transition: {
            stiffness: 150,
            damping: 15,
            mass: 5,
        },
    }
}

export const animItem1 = {
    initial: {
        filter: 'blur(3px)',
        opacity: 0
    },
    enter: {
        filter: 'blur(0px)',
        opacity: 1,
        transition: {
            type: 'spring',
            duration: 6000,
            delay: 500,
        },
    }
}

// END FADE //

export const growStroke = {
    initial: { height: `${0}vh` },
    enter: {
        height: `${45}vh`,
        transition: {
            type: 'spring',
            duration: 2000,
            delay: 100,
        },
    }
}

export const fadeNegatif = function () {
    if (window.innerWidth <= 1080) {
        return {
            initial: { opacity: 1 },
            enter: {
                opacity: 1,
                transition: {
                    type: 'keyframes',
                    ease: 'easeIn',
                    duration: 1000,
                    delay: 450,
                }
            }
        }
    } else {
        return {
            initial: { opacity: 0 },
            enter: {
                opacity: 1,
                transition: {
                    type: 'keyframes',
                    ease: 'easeIn',
                    duration: 1000,
                    delay: 450,
                }
            }
        }
    }
}()


export const negatif = {
    initial: { filter: `grayscale(1) contrast(5) brightness(2) invert(0)` },
    enter: {
        filter: `grayscale(0) contrast(1) brightness(1) invert(0)`,
        transition: {
            type: 'spring',
            duration: 6700,
            delay: 600,
        },
    }
}