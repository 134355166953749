<template>
  <div class="menu-page">

    <div class="titre--site" @click="navigateToHome">
      <img v-motion="fade4" src="../../../src/assets/img/cursor-home-hq.svg" />
    </div>

    <div class="menu--edition solo" style="right: 3vw">
      <div class="info--edition" @click="showIndexEdition">index ⋯</div>
    </div>

    <div class="device--menu" v-motion="fade4">
      <div class="left--device link3" @click="activateLeftKey">←</div>
      <div class="right--device link3" @click="activateRightKey">→</div>
    </div>

    <div class="home--mobile link3" @click="showIndexEdition" v-motion="fade4">⋯</div>

    <div class="index-gallery linkIndex blanche" v-motion="fade2">⋯ Index</div>
    <div class="index-gallery documentTitle linkIndex opacity-zero">⋯ &nbsp;{{ documentTitle }}</div>

    <div class="menu--index opacity-zero desktop">
      <div class="index-gallery-overlay fromEdition opacity-zero link4 blackText" @click="hideIndexEditionDesk">➔</div>
    </div>
    <div class="back--text opacity-zero link2" @click="hideIndexEdition">⋯ info</div>

    <div class="menu--index opacity-zero mobile">
      <div class="index-gallery-overlay link2 blackText">➔</div>
      <div class="index-gallery-overlay link2 blackText">➔</div>
      <div class="info-gallery-overlay link2 blackText" @click="infoYear">info</div>
    </div>

    <div class="button-scroll" :style="{ zIndex: buttonScrollZIndex }">
      <div class="button-left" @touchstart="activateLeftKey"></div>
      <div class="button-right debut" @click="activateRightKey" @touchstart="activateRightKey"></div>
    </div>

  </div>
</template>

<script>
import GalleryScriptMenu from '../../directives/GalleryScriptMenu.js';
import { fade2, fade3, fade4 } from '../../directives/AnimationHome.js';

export default {
  name: 'MenuGallery',
  mixins: [GalleryScriptMenu],
  data() {
    return {
      fade2, fade3, fade4,
      buttonScrollZIndex: 99,
      documentTitle: document.title
    }
  },
  methods: {
    decreaseZIndex() {
      setTimeout(() => {
        this.buttonScrollZIndex = Math.max(99, this.buttonScrollZIndex - 300);
        this.decreaseZIndex();
      }, 800);
    },
  },
  mounted() {
    setTimeout(this.decreaseZIndex, 1000);

    const elementsToTrack = document.querySelector('.info--edition');
    const backTrack = document.querySelector('.titre--site');
    const titreprojet = document.querySelector('.documentTitle');
    const indexMobile = document.querySelector('.home--mobile');
      
    function gtag() {
      window.dataLayer.push(arguments);
    }

    elementsToTrack.addEventListener('click', () => {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'index_all_pages', {
        'event_category': 'Click',
        'event_label': 'click_index_all_pages'
      });
    });

    indexMobile.addEventListener('click', () => {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'index_all_pages', {
        'event_category': 'Click',
        'event_label': 'click_index_all_pages'
      });
    });

    backTrack.addEventListener('click', () => {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'to_home_from_all_pages', {
        'event_category': 'Click',
        'event_label': 'click_back_to_home_from_all_pages'
      });

      if (this.$route.name === 'PetiteChoseSansValeur') {
        gtag('event', 'to_home_from_petite_chose_sans_valeur', {
        'event_category': 'Click',
        'event_label': 'click_back_to_home_from_petite_chose_sans_valeur'
      });
      }

      if (this.$route.name === 'PuzzlePage') {
        gtag('event', 'to_home_from_puzzle', {
        'event_category': 'Click',
        'event_label': 'click_back_to_home_from_puzzle'
      });
      }

      if (this.$route.name === 'FictionDiurne') {
        gtag('event', 'to_home_from_fiction_diurne', {
        'event_category': 'Click',
        'event_label': 'click_back_to_home_from_fiction_diurne'
      });
      }

      if (this.$route.name === 'ChampDeRiviere') {
        gtag('event', 'to_home_from_champ_de_riviere', {
        'event_category': 'Click',
        'event_label': 'click_back_to_home_from_champ_de_riviere'
      });
      }

    });

    titreprojet.addEventListener('click', () => {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'text_project_all_pages', {
        'event_category': 'Click',
        'event_label': 'click_back_to_text_project_all_pages'
      });

      if (this.$route.name === 'PetiteChoseSansValeur') {
        gtag('event', 'text_project_petite_chose_sans_valeur', {
        'event_category': 'Click',
        'event_label': 'click_back_to_text_project_petite_chose_sans_valeur'
      });
      }

      if (this.$route.name === 'PuzzlePage') {
        gtag('event', 'text_project_puzzle', {
        'event_category': 'Click',
        'event_label': 'click_back_to_text_project_puzzle'
      });
      }

      if (this.$route.name === 'FictionDiurne') {
        gtag('event', 'text_project_fiction_diurne', {
        'event_category': 'Click',
        'event_label': 'click_back_to_text_project_fiction_diurne'
      });
      }
    });
  }
}
</script>

<style lang="scss"></style>