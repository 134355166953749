<template>
  <div class="move" @contextmenu.prevent="showEmailDiv">

    <div class="main--gallery theme--one horizontal" @touchstart="handleTouchStart" @gesturechange="handleGestureChange"
      @touchmove="handleTouchMove" @touchend="handleTouchEnd" ref="elementToDisableSwipeAndZoom">

      <div ref="descriptionFirst" class="dark--grain description item current" @click.stop="disableClick">

        <div class="bottom-right">
          <div class="main--img one">
            <div class="layer--black"></div>
            <img ref="pictureItem"
              src="../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_main.jpg" alt="" />
          </div>
        </div>
      </div>

      <figure v-motion="fadeText" v-for="(image, index) in images"
        :key="index === images.length - 1 ? 'lastPicture' : index" :ref="'pictureItem' + index"
        :class="['picture FADEIN red-pointer', 'item antique center-self', image.class, { 'lastItem': index === images.length - 1 }]">
        <div :class="['image-container gallery--two', image.classContainer]">
          <div :class="['image-item leg-left', image.classDiv1]">
            <img v-if="image.src" :src="image.src" :class="image.class1" :alt="altText" />
          </div>
          <div :class="['image-item leg-right', image.classDiv2]" v-if="image.hasSecondImage">
            <img :src="image.src2" :class="image.class2" @click="zoomImg(index)" :alt="altText" />
          </div>
          <div :class="['image-item leg-third', image.classDiv3]" v-if="image.hasThirdImage">
            <img :src="image.src3" :class="image.class3" @click="zoomImg(index)" :alt="altText" />
          </div>
          <div :class="['image-item leg-fourth', image.classDiv4]" v-if="image.hasFourthImage">
            <img :src="image.src4" :class="image.class4" @click="zoomImg(index)" :alt="altText" />
          </div>
          <div :key="'textSection' + index" v-if="image.hasText" :class="['centered-text', image.classText]">
            <h1 v-html="image.text"></h1>
          </div>
          <div :class="['caption', image.classCaption]">
            <figcaption v-html="image.title" :class="['desktop', image.classText]"></figcaption>
          </div>
        </div>
        <figcaption v-html="image.title" :class="['mobile', image.classText]"></figcaption>
      </figure>

    </div>
    <MenuGallery />

    <div class="figure-counter pages opacity-zero" style="pointer-events: none" v-motion="fade4">
      <span class="current-counter">{{ currentImageIndex }}</span><span class="slash-counter"> — </span> <span
        class="total-counter">{{ totalFigures }}</span>
    </div>

    <div class="red-pointer loader--gif">
      <h1 class="meno" style="opacity:0.5">{{ documentTitle }}</h1>
      <div class="img--home">
        <div class="layer--black"></div>
        <img :key="currentImage"
          src="../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_main.jpg" />
      </div>
    </div>

  </div>
</template>

<script>
import GalleryScript from '../../directives/GalleryScript.js';
import GalleryHorizontal from '../../directives/GalleryScriptHorizontal.js';
import GalleryScriptObserver from '../../directives/GalleryScriptObserver.js';
import { fade2, fadeText, fade3, fade4 } from '../../directives/AnimationHome.js';

import MenuGallery from "../menu/MenuGallery.vue";
// import { pageview } from 'vue-gtag'

export default {
  name: 'PetiteChoseSansValeur',
  components: { MenuGallery },
  mixins: [GalleryScript, GalleryHorizontal, GalleryScriptObserver],
  data() {
    return {
      documentTitle: document.title,
      currentImageIndex: 0,
      currentIndex: null,
      fade2, fadeText, fade3, fade4,
      altText: "Jason Pumo, Petite Chose Sans Valeur, table pique-nique, objet, arbre, bois, dark, portrait, art, artiste, argentique, analog, film",
      images: [

        {
          hasText: true, src: '', title: '', class: 'cartel-text xl-pad-left xl-pad-right first red-pointer', classText: 'mobile-fit',
          text: `<span class="titre style--un">Petite Chose Sans Valeur</span><span class="annee">— &nbsp; 2015 . 2020</span>
          <div class="ensemble">
            <div class="francais">
          <p>Toute chose naît d'une nécessité à être montrer, partager ou simplement à exister sans aucun autre but que de laisser une trace.
            Un romantisme dont le medium photographique ne peut se séparer.
            En dehors de cette romance visuelle, la non-nécessité à produire, celle de l'exercice, de l'<i>anti-page-blanche</i>.</p>
            </div>
            <div class="anglais">
          <p>Everything is born of a need to be shown, shared or simply to exist, with no other aim than to leave a trace.
It's a romanticism from which the photographic medium cannot escape.
In addition to this visual romanticism, there's the non-necessity of production, of exercise, of the <i>anti-blank-page</i>.</p>
            </div>
            </div>
            <div class="ensemble">
            <div class="francais">
          <p><i>Petite chose sans valeur</i> retrace le cheminement photographique d'un même sujet.
                  Entre obsession et rencontre hasardeuse, ce projet confronte une période romantique
                  à une période plus pragmatique. Dans cette échange temporel, le croquis furtif d'une pensée,
                  celui de la non-nécessité devenu nécessité.</p>
            </div>
            <div class="anglais">
          <p><i>Worthless little thing</i> follows the photographic path of a single subject.
            Between obsession and serendipity, this project confronts a romantic period with a more pragmatic one.
            In this temporal exchange, the furtive sketch of a thought, that of non-necessity becoming necessity.</p>
            </div>
            </div>
            <div class="ensemble">
            <div class="francais"><p><i>pique-nique (n.m) : apporter quelque chose à manger sans réelle valeur lors d'un repas en extérieur.</i></div>
            <div class="anglais">
          <p><i>picnic : to bring something to be eaten with no significant worth during an open-air meal.</i></p>
            </div>
            </div>`
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_1.jpg'),
          title: "Auto-édition, 20.5 x 24.5 cm, 98 pages"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_2.jpg'),
          title: "Sans titre, <span>2016</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_3.jpg'),
          title: "Sans titre, <span>2016</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_4.jpg'),
          title: "Sans titre, <span>2020</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_5.jpg'),
          title: "Sans titre, <span>2016</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_6.jpg'),
          title: "Sans titre, <span>2016</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_7.jpg'),
          title: "Dernier rêve, <span>2016</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_8.jpg'),
          title: "Sans titre, <span>2020</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_9.jpg'),
          title: "Sans titre, <span>2020</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_10.jpg'),
          title: "Sans titre, <span>2016</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_11.jpg'),
          title: "Sans titre, <span>2017</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_12.jpg'),
          title: "Sans titre, <span>2015</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_13.jpg'),
          title: "Sans titre, <span>2015</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_14.jpg'),
          title: "Seulâbre, <span>2016</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_15.jpg'),
          title: "Sans titre, <span>2020</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_16.jpg'),
          title: "Pulsions, <span>2015</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_17.jpg'),
          title: "Sans titre, <span>2015</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_18.jpg'),
          title: "Sans titre, <span>2016</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_19.jpg'),
          title: "Pulsions II, <span>2016</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_20.jpg'),
          title: "Sans titre, <span>2020</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_21.jpg'),
          title: "Sans titre, <span>2017</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_22.jpg'),
          title: "Sans titre, <span>2016</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_23.jpg'),
          title: "Entre deux, <span>2016</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_24.jpg'),
          title: "Sans titre, <span>2019</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_25.jpg'),
          title: "Sans titre, <span>2020</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_26.jpg'),
          title: "Sans titre, <span>2020</span>"
        },
        {
          class1: 'single-img medium-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_27.jpg'),
          title: "Sans titre, <span>2021</span>"
        },
      ],
    }
  },
  // setup() {
  //   const track = () => {
  //     pageview({ page_path: '/petite-chose-sans-valeur' });
  //     pageview('/petite-chose-sans-valeur');
  //   }
  //   return {
  //     track
  //   }
  // },
  mounted() {
    document.body.classList.add('oliveBG');
    document.querySelector('.index-gallery.documentTitle').classList.add('meno');
    document.querySelector('.info--edition').classList.add('meno');
    document.querySelector('.info--edition').classList.add('index-of-petite-chose-sans-valeur');

    const IndexPetite = document.querySelector('.index-of-petite-chose-sans-valeur');


    function gtag() {
      window.dataLayer.push(arguments);
    }

    IndexPetite.addEventListener('click', () => {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'index_petite_chose_sans_valeur', {
        'event_category': 'Click',
        'event_label': 'click_on_index_petite_chose_sans_valeur'
      });
    });

    const indexMobile = document.querySelector('.home--mobile');
    indexMobile.addEventListener('click', () => {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'index_petite_chose_sans_valeur', {
        'event_category': 'Click',
        'event_label': 'click_on_index_petite_chose_sans_valeur'
      });
    });

    const lastItem = document.querySelector('.lastItem');

    if (lastItem.classList.contains("current")) {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'reach_end_petite_chose_sans_valeur', {
        'event_category': 'Click',
        'event_label': 'reach_end_petite_chose_sans_valeur'
      });
    }
  },
  unmounted() {
    document.body.classList.remove('oliveBG');
    document.querySelector('.index-gallery.documentTitle').classList.remove('meno');
    document.querySelector('.info--edition').classList.remove('meno');
    document.querySelector('.info--edition').classList.remove('index-of-petite-chose');
  }
}
</script>