<template>
  <div class="info--main" @contextmenu.prevent="showEmailDiv">

    <div class="back--site link" @click="navigateToHomeFromInfo()" v-motion="fade2">➔</div>

    <div id="languageToggle" class="changement link" v-motion="fade2">
      <div class="btn-francais btn-underline" @click="toggleLanguage('fr')">FR</div>
      <div class="btn-slash">/</div>
      <div class="btn-anglais" @click="toggleLanguage('en')">EN</div>
    </div>

    <div class="insta">
      <a class="link tag_insta" href="https://www.instagram.com/jasonpumo" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" x="0px"
          y="0px" width="20" height="20" viewBox="0,0,256,256">
          <g fill="#ffe4b5" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
            stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none"
            font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
            <g transform="scale(10.66667,10.66667)">
              <path
                d="M8,3c-2.757,0 -5,2.243 -5,5v8c0,2.757 2.243,5 5,5h8c2.757,0 5,-2.243 5,-5v-8c0,-2.757 -2.243,-5 -5,-5zM8,5h8c1.654,0 3,1.346 3,3v8c0,1.654 -1.346,3 -3,3h-8c-1.654,0 -3,-1.346 -3,-3v-8c0,-1.654 1.346,-3 3,-3zM17,6c-0.55228,0 -1,0.44772 -1,1c0,0.55228 0.44772,1 1,1c0.55228,0 1,-0.44772 1,-1c0,-0.55228 -0.44772,-1 -1,-1zM12,7c-2.757,0 -5,2.243 -5,5c0,2.757 2.243,5 5,5c2.757,0 5,-2.243 5,-5c0,-2.757 -2.243,-5 -5,-5zM12,9c1.654,0 3,1.346 3,3c0,1.654 -1.346,3 -3,3c-1.654,0 -3,-1.346 -3,-3c0,-1.654 1.346,-3 3,-3z">
              </path>
            </g>
          </g>
        </svg>
      </a>
    </div>

    <div class="block--flex" v-motion="fade2">

      <div class="block one">
        <div class="infopage--bio">
          <div class="info--texte">
            <span class="titre">Jason Pumo</span>
            <span class="titre-2 line" v-if="showFrenchText">1991 · <i>vit et travaille à Arles</i></span>
            <span class="titre-2 line" v-if="showEnglishText">1991 · <i>based in Arles</i></span>
            <div class="infopage--mail">
              <a class="tag_mail" href="mailto:mail@jasonpumo.com">mail@jasonpumo.com</a>
            </div>
            <p v-if="showFrenchText">
              Dans une démarche proche du récit photographique, mes travaux portent réflexion sur la perception du monde
              par l'image latente. Mon intention photographique est de retranscrire
              une certaine considération du réel, de le transposer dans le faussement figé.</p>

            <p v-if="showEnglishText">
              In an approach close to photographic storytelling, my work reflects on the perception of the world through
              the latent image. My photographic intention is to retranscribe a certain consideration of reality, to
              transpose it into the
              falsely frozen.</p>
          </div>
        </div>
      </div>

      <div class="block two">
        <div class="infopage--titre" v-if="showFrenchText">Parcours</div>
        <div class="infopage--titre" v-if="showEnglishText">Background</div>
        <div class="infopage--expo">
          <li>
            <div class="annee">2022</div>
            <div class="event">
              <span class="titre-2" v-if="showFrenchText">Résidence Rouvrir le Monde</span>
              <span class="titre-2" v-if="showEnglishText">Residency Rouvrir le Monde</span>
              <span class="ville" v-if="showFrenchText">Drac Provence-Alpes-Côte d'Azur<br />Vallée de la Vésubie (Alpes,
                France)</span>
              <span class="ville" v-if="showEnglishText">Drac Provence-Alpes-Côte d'Azur<br />Vesubia Valley (Alps,
                France)</span>
            </div>
          </li>
          <li>
            <div class="annee">2022</div>
            <div class="event">
              <span class="titre-2" v-if="showFrenchText">Diplômé de l'École Nationale Supérieure de la
                Photographie</span>
              <span class="titre-2" v-if="showEnglishText">Graduate of the French National Superior School of
                Photography</span>
              <span class="ville" v-if="showFrenchText">formation technique, séminaires critiques<br />et connaissances
                théoriques de l’image</span>
              <span class="ville" v-if="showEnglishText">technical training, critical seminars<br />and image
                theory</span>
              <span class="type-2" v-if="showFrenchText">Master niveau I</span>
              <span class="type-2" v-if="showEnglishText">Master's degree</span>
              <span class="ville spe" v-if="showFrenchText"><i
                  style="text-transform: lowercase; font-family:'meno-display';">Félicitations du Jury</i></span>
              <span class="ville spe" v-if="showEnglishText"><i
                  style="text-transform: lowercase; font-family:'meno-display';">Jury's congratulations</i></span>
              <span class="ville">Arles, France</span>

            </div>
          </li>
          <li>
            <div class="annee">2013</div>
            <div class="event">
              <span class="titre-2" v-if="showFrenchText">Diplômé en Science de l’Information et de la
                Communication</span>
              <span class="titre-2" v-if="showEnglishText">Graduate in Information and Communication Science</span>
              <span class="ville" v-if="showFrenchText">art, communication visuelle et nouvelles technologies</span>
              <span class="ville" v-if="showEnglishText">art, visual communication and new technology</span>
              <span class="type-2" v-if="showFrenchText">Licence niveau II</span>
              <span class="type-2" v-if="showEnglishText">bachelor's degree</span>
              <span class="ville">Nice, France</span>
            </div>
          </li>
        </div>
      </div>

      <div class="block three">
        <div class="infopage--titre" v-if="showFrenchText">Expositions</div>
        <div class="infopage--titre" v-if="showEnglishText">Exhibitions</div>
        <div class="infopage--expo">
          <li>
            <div class="event">
              <span class="expos">*<i>En longeant l'orée</i> · Galerie Itinérante · Espace Van Gogh, Arles · 2023</span>
              <span class="expos">*<i>Hasards objectifs</i> · Été Indien(s) · ENSP, Arles · 2022</span>
              <span class="expos">*<i>Work in progress</i> · Les Rencontres de la Photographie · Le Printemps, Arles ·
                2022</span>

              <span class="expos" v-if="showFrenchText">*<i>Méridiens</i> · Galerie Volver, Arles · 2022</span>
              <span class="expos" v-if="showEnglishText">*<i>Méridiens</i> · Volver Gallery, Arles · 2022</span>

              <span class="expos" v-if="showFrenchText">*<i>Fringale</i> · Été Indien(s) · Librairie Actes Sud, Arles ·
                2020</span>
              <span class="expos" v-if="showEnglishText">*<i>Fringale</i> · Été Indien(s) · Actes Sud Bookshop, Arles ·
                2020</span>

              <span class="expos" v-if="showFrenchText">*<i>Work in progress</i> · Galerie Croisière, Arles · 2020</span>
              <span class="expos" v-if="showEnglishText">*<i>Work in progress</i> · Croisière Gallery, Arles · 2020</span>

              <span class="expos" v-if="showFrenchText">*<i>COD #3</i> · AE.ENSP · Galerie Arena, Arles · 2019</span>
              <span class="expos" v-if="showEnglishText">*<i>COD #3</i> · AE.ENSP · Arena Gallery, Arles · 2019</span>

              <span class="expos" v-if="showFrenchText">*<i>Liberté chérie</i> · UMAM · Galerie Lympia, Nice · 2019</span>
              <span class="expos" v-if="showEnglishText">*<i>Liberté chérie</i> · UMAM · Galerie Lympia, Nice ·
                2019</span>

              <span class="expos">**<i>Wonderland</i> · Villa Wonderland, Cannes · 2018</span>

              <span class="expos" v-if="showFrenchText">*<i>Sehnsucht Opening</i> · Galerie Sehnsucht, Rotterdam ·
                2017</span>
              <span class="expos" v-if="showEnglishText">*<i>Sehnsucht Opening</i> · Galerie Sehnsucht, Rotterdam ·
                2017</span>

              <span class="expos" v-if="showFrenchText">*<i>Art & Leadership</i> · / Prix du jury / · Campus St-Jean
                d'Angely, Nice · 2016</span>
              <span class="expos" v-if="showEnglishText">*<i>Art & Leadership</i> · / Jury's Prize / · St-Jean d'Angely
                Campus, Nice · 2016</span>

              <span class="expos" v-if="showFrenchText">*<i>Free gallery</i> · Exposition sauvage éphémère ·
                St-Jean-Cap-Ferrat · 2015</span>
              <span class="expos" v-if="showEnglishText">*<i>Free gallery</i> · Ephemeral exhibition · St-Jean-Cap-Ferrat
                · 2015</span>

              <span class="expos">***<i>Un festival c'est trop court</i> · Programme Scopitone · Héliotrope, Nice ·
                2014</span>
              <span class="expos">*<i>ArtCrasher Exhibition #2</i> · Venice Arts, Los Angeles · 2014</span>
              <span class="expos">*<i>ArtCrasher Exhibition #1</i> · SomArts Cultural Center, San Francisco · 2014</span>

              <span class="expos" v-if="showFrenchText">***<i>Néons (Exposition d'Anthony Mirial)</i> · Galerie Maud
                Barral, Nice · 2013</span>
              <span class="expos" v-if="showEnglishText">***<i>Néons (Mirial's Exhibition)</i> · Maud Barral Gallery, Nice
                · 2013</span>

              <span class="expos" v-if="showFrenchText">*<i>Collectif étudiant</i> · MuseAav, Nice · 2011</span>
              <span class="expos" v-if="showEnglishText">*<i>Student collective</i> · MuseAav, Nice · 2011</span>

              <br />
              <br />

              <span class="expos detail" v-if="showFrenchText"><i>*exposition collective</i></span>
              <span class="expos detail" v-if="showEnglishText"><i>*collective exhibition</i></span>

              <span class="expos detail" v-if="showFrenchText"><i>**exposition personnelle</i></span>
              <span class="expos detail" v-if="showEnglishText"><i>**personal exhibition</i></span>

              <span class="expos detail" v-if="showFrenchText"><i>***diffusion film court</i></span>
              <span class="expos detail" v-if="showEnglishText"><i>***short film projection</i></span>
            </div>
          </li>
        </div>
      </div>
    </div>

    <div class="bg--film--contain--info" v-motion="fade2">
      <div class="bg--film--info"></div>
      <img src="../assets/img/home/homepage-4.jpg">
    </div>

    <div class="red-pointer loader--gif">
      <div class="img--home">
        <div class="layer--black-overlay"></div>
      </div>
    </div>

  </div>
</template>

<script>
// import { pageview } from 'vue-gtag';
import { fade5, fade2, fade3, fade4, fade6 } from '../directives/AnimationHome.js';

export default {
  name: 'InfoPage',
  el: '#languageToggle',
  data() {
    return {
      showFrenchText: true,
      showEnglishText: false,
      fade5, fade2, fade3, fade4, fade6
    }
  },
  // setup() {
  //   const track = () => {
  //     pageview({ page_path: '/info' });
  //     pageview('/info');
  //   }
  //   return {
  //     track
  //   }
  // },
  methods: {
    toggleLanguage(language) {
      if (language === 'fr') {
        this.showFrenchText = true;
        this.showEnglishText = false;
      } else if (language === 'en') {
        this.showFrenchText = false;
        this.showEnglishText = true;
      }
    },
    navigateToHomeFromInfo() {
      const self = this;
      const loader = document.querySelector('.loader--gif');
      // const blocks = document.querySelector('.block--flex');
      // const back = document.querySelector('.back--site');

      loader.classList.add('overlay--loader');
      // setTimeout(() => {
      //   blocks.style.display = "none";
      //   back.style.display = "none";
      //   window.scrollTo({
      //     top: 0,
      //     left: 0,
      //     behavior: 'smooth'
      //   });
      // }, 200);
      setTimeout(function () {
        self.$router.push('/');
      }, 200);
    },
    showEmailDiv(event) {
      const emailDiv = document.getElementById("email");
      const cursor = document.querySelector(".cursor");
      const follower = document.querySelector(".cursor-follower");

      emailDiv.style.opacity = "1";
      emailDiv.style.pointerEvents = "auto";

      emailDiv.style.top = event.clientY + "px";
      emailDiv.style.left = event.clientX + "px";

      emailDiv.addEventListener('mouseover', function () {
        emailDiv.style.opacity = "1";
        emailDiv.style.pointerEvents = "auto";
        cursor.classList.add('no-cursor');
        follower.classList.add('no-cursor');
      })

      emailDiv.addEventListener('mouseleave', function () {
        emailDiv.style.opacity = "0";
        emailDiv.style.pointerEvents = "none";
        cursor.classList.remove('no-cursor');
        follower.classList.remove('no-cursor');
      })
    }
  },
  mounted() {
    const cursor = document.querySelector(".cursor");
    const follower = document.querySelector(".cursor-follower");
    const infoText = document.querySelector(".info--texte");
    const blocks = document.querySelector('.block--flex');
    const back = document.querySelector('.back--site');

    blocks.style.display = "";
    back.style.display = "";

    // CHANGE BACKGROUND ONLOAD
    setTimeout(() => {
      document.body.classList.remove('antiqueBG');
      document.body.classList.remove('zoomBG');
      document.body.classList.add('grainBG');
      document.body.style = "";
      cursor.classList.remove('active-home');
      follower.classList.remove('home');
      cursor.classList.remove('active2');
      follower.classList.remove('active2');
      cursor.classList.add('info');
      follower.classList.add('info');
    }, 50);

    // HOVER BLOCK OPACITY
    const hovers = document.querySelectorAll('li')
    for (let i = 0; i < hovers.length; i++) {
      hovers[i].classList.add('opacity--info');
    }
    hovers.forEach(function (hover) {
      hover.addEventListener("mouseenter", function () {
        this.classList.remove('opacity--info');
      });
      hover.addEventListener("mouseleave", function () {
        this.classList.add('opacity--info');
      });
    });

    //BTN LANGUAGES
    // Get a reference to the parent container
    const languageToggle = document.getElementById('languageToggle');

    // Add a click event listener to the parent container
    languageToggle.addEventListener('click', (event) => {
      const clickedElement = event.target;

      if (clickedElement.classList.contains('btn-francais')) {
        // If the "FR" button is clicked
        clickedElement.classList.add('btn-underline');
        document.querySelector('.btn-anglais').classList.remove('btn-underline');
        gtag('event', 'info_fr', {
        'event_category': 'Click',
        'event_label': 'click_on_info_button_fr'
      });
      } else if (clickedElement.classList.contains('btn-anglais')) {
        // If the "EN" button is clicked
        clickedElement.classList.add('btn-underline');
        document.querySelector('.btn-francais').classList.remove('btn-underline');
        gtag('event', 'info_en', {
        'event_category': 'Click',
        'event_label': 'click_on_info_button_en'
      });
      }
    });
    // CURSOR CHANGE 1
    const linksCursor = document.querySelectorAll(".link");
    linksCursor.forEach(linkCursor => {
      linkCursor.addEventListener("mouseenter", function () {
        cursor.classList.add("active2");
        follower.classList.add("active2");
      });
      linkCursor.addEventListener("mouseleave", function () {
        cursor.classList.remove("active2");
        follower.classList.remove("active2");
      });
    });

    // CURSOR CHANGE 1
    hovers.forEach(linkCursor => {
      linkCursor.addEventListener("mouseenter", function () {
        cursor.classList.add("active-info");
        follower.classList.add("active-info");
      });
      linkCursor.addEventListener("mouseleave", function () {
        cursor.classList.remove("active-info");
        follower.classList.remove("active-info");
      });
    });

    infoText.addEventListener("mouseenter", function () {
      cursor.classList.add("active-info");
      follower.classList.add("active-info");
    });
    infoText.addEventListener("mouseleave", function () {
      cursor.classList.remove("active-info");
      follower.classList.remove("active-info");
    });


    //GTAG EVENT
    function gtag() {
      window.dataLayer.push(arguments);
    }

    const mail = document.querySelector('.tag_mail');
    const backTrackInfo = document.querySelector('.back--site');
    const insta = document.querySelector('.tag_insta');

    mail.addEventListener('click', () => {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'info_mail', {
        'event_category': 'Click',
        'event_label': 'click_on_page_info_mail'
      });
    });

    insta.addEventListener('click', () => {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'info_insta', {
        'event_category': 'Click',
        'event_label': 'click_on_instagram_link_info'
      });
    });

    backTrackInfo.addEventListener('click', () => {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'to_home_from_info', {
        'event_category': 'Click',
        'event_label': 'click_back_to_home_from_info'
      });

      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'to_home_from_all_pages', {
        'event_category': 'Click',
        'event_label': 'click_back_to_home_from_all_pages'
      });

    });

  },
  unmounted() {
    const cursor = document.querySelector(".cursor");
    const follower = document.querySelector(".cursor-follower");

    cursor.classList.remove('active');
    follower.classList.remove('active');

    cursor.classList.remove('info');
    follower.classList.remove('info');

    // const loader = document.querySelector('.loader--gif');
    // loader.classList.remove('overlay--loader');
  }
}
</script>

<style lang="scss">
.insta {
  position: fixed;
  bottom: 0px;
  z-index: 100;
  left: 0px;
  padding: 20px;
  opacity: 1;
  transition: all 0.6s ease;
}

.insta:hover {
  opacity: 0.6;
  transition: all 0.6s ease;
}

.insta a {
  cursor: none !important;
}

@media only screen and (max-width: 1024px) {
  .insta {
    left: -10px;
    bottom: -10px;
  }

}
</style>