export default {
  mounted() {
    const titreSite = document.querySelector('.titre--site');
    const titreSiteImg = document.querySelector('.titre--site img');
    const counter = document.querySelector('.figure-counter.pages');
    const homeMobile = document.querySelector('.home--mobile');
    const backtext = document.querySelector('.back--text');
    const index = document.querySelector('.menu--edition');
    const indexGallery = document.querySelector('.index-gallery.documentTitle');
    const indexGalleryOverlay = document.querySelector('.index-gallery-overlay');
    const cursorLeft = document.querySelector('.button-left');
    const cursorRight = document.querySelector('.button-right');

    setTimeout(function () {
      document.body.classList.add('blackBG');
      document.body.classList.remove('antiqueBG');
    }, 50);

    titreSiteImg.classList.add('theme--two');
    titreSite.classList.add('theme--two');
    counter.classList.add('theme--two');
    backtext.classList.add('theme--two');
    homeMobile.classList.add('theme--two');
    index.classList.add('theme--two');
    indexGallery.classList.add('theme--two');
    indexGalleryOverlay.classList.add('theme--two');
    cursorLeft.classList.add('theme--two');
    cursorRight.classList.add('theme--two');
  },
  unmounted() {
  }
}