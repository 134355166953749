<template>
  <div class="main" @contextmenu.prevent="showEmailDiv">

    <div class="header link-blue">
      <a v-motion="animItem1">
        <span class="name">Jason Pumo</span>
        <!-- <span><a @click="navigateFromHomeToInfo()">Info</a> • <router-link to="/archives">Archives</router-link></span> -->
        <span><a @click="navigateFromHomeToInfo()">Info</a></span>
      </a>
    </div>

    <div class="bg--film--contain">
      <div id="parallax info" class="bg--film">
        <img id="imgFilm" class="bureau-1025 desktop" src="../assets/img/home/homepage.jpg">
        <img id="imgFilm" class="bureau-1024" src="../assets/img/home/homepage-2.jpg">
      </div>
    </div>

    <div class="items-gallery" v-motion="animItem1">
      <div class="items">

        <div class="item-titre">
          <a @click="gotoRiviere" @mouseover="changeRiviere" @mouseleave="resetImageRiviere">
            Champ de Rivière
            <span>2022</span>
          </a>
        </div>

        <div class="item-titre">
          <a @click="gotoFiction" @mouseover="changeFictionDiurne" @mouseleave="resetImageFiction">
            Fiction Diurne
            <span>2021</span>
          </a>
        </div>

        <div class="item-titre">
          <a @click="gotoChose" @mouseover="changePetiteChose" @mouseleave="resetImageChose">
            Petite Chose Sans Valeur
            <span>2015 — 2020</span>
          </a>
        </div>

        <div class="item-titre">
          <a @click="gotoPuzzle" @mouseover="changePuzzle" @mouseleave="resetImagePuzzle">
            Puzzle
            <span>2015</span>
          </a>
        </div>

      </div>
    </div>

    <div class="item-img riviere--home link-blue">
      <div class="img--home">
        <div class="layer--riviere"></div>
        <div class="layer--black"></div>
        <img :key="currentImage" src="../assets/img/champ-de-riviere/jason-pumo_champ-de-riviere_main.jpg" />
      </div>
    </div>

    <div class="item-img fiction--home link-blue">
      <div class="img--home">
        <div class="layer--black"></div>
        <img :key="currentImage" src="../assets/img/fiction-diurne/jason-pumo_fiction-diurne_main.jpg" />
      </div>
    </div>

    <div class="item-img chose--home link-blue">
      <div class="img--home">
        <div class="layer--black"></div>
        <img :key="currentImage"
          src="../assets/img/petite-chose-sans-valeur/jason-pumo_petite-chose-sans-valeur_main.jpg" />
      </div>
    </div>

    <div class="item-img puzzle--home link-blue">
      <div class="img--home">
        <div class="layer--black"></div>
        <img :key="currentImage" src="../assets/img/puzzle/jason-pumo_puzzle_main.jpg" />
      </div>
    </div>

    <div class="red-pointer loader--gif">
      <div class="img--home">
        <div class="layer--black-overlay"></div>
      </div>
    </div>

  </div>
</template>

<script>
// import { pageview } from 'vue-gtag';
import { animItem1, fade2, fade3 } from '../directives/AnimationHome.js';

export default {
  name: 'HomePage',
  components: {
  },
  // setup() {
  //   const track = () => {
  //     pageview({ page_path: '/' });
  //     pageview('/');
  //   }
  //   return {
  //     track
  //   }
  // },
  data() {
    return {
      currentImage: require('../assets/img/home/blank.png'),
      ListImages: [
        {
          src: require('../assets/img/home/homepage.jpg'),
          style: 'object-position: right',
          class: 'img--1'
        }
      ],
      isMouseMoveListenerActive: false,
      previousImage: null,
      animItem1,
      fade2, fade3,
      scale: 1
    }
  },
  methods: {
    navigateFromHomeToInfo() {
      const self = this;
      const loader = document.querySelector('.loader--gif');

      loader.classList.add('overlay--loader');
      setTimeout(function () {
        self.$router.push('/info');
      }, 200);
    },
    gotoRiviere() {
      const items = document.querySelector('.items');
      const header = document.querySelector('.header.link-blue');
      items.style.opacity = "0";
      header.style.opacity = "0";
      setTimeout(function () {
        this.$router.push('/champ-de-riviere');
      }.bind(this), 450);
    },
    gotoFiction() {
      const items = document.querySelector('.items');
      const header = document.querySelector('.header.link-blue');
      items.style.opacity = "0";
      header.style.opacity = "0";
      setTimeout(function () {
        this.$router.push('/fiction-diurne');
      }.bind(this), 450);
    },
    gotoChose() {
      const items = document.querySelector('.items');
      const header = document.querySelector('.header.link-blue');
      items.style.opacity = "0";
      header.style.opacity = "0";
      setTimeout(function () {
        this.$router.push('/petite-chose-sans-valeur');
      }.bind(this), 450);
    },
    gotoPuzzle() {
      const items = document.querySelector('.items');
      const header = document.querySelector('.header.link-blue');
      items.style.opacity = "0";
      header.style.opacity = "0";
      setTimeout(function () {
        this.$router.push('/puzzle');
      }.bind(this), 450);
    },
    startScale() {
      const follower = document.querySelector(".cursor-follower");
      const titles = document.querySelectorAll(".item-titre a");

      titles.forEach(title => {
        title.style.color = "chocolate";
      });

      this.scale = 180;
      follower.classList.remove('home');
      follower.style.transition = 'transform 15s';
      follower.style.transform = `scale(${this.scale})`;

      const img = document.querySelector('.bg--film');

      this.timeoutID1 = setTimeout(function () {
        img.style.backgroundColor = "rgb(92 171 255)";
        img.style.filter = "brightness(2)";
      }, 1000);

      this.timeoutID2 = setTimeout(function () {
        img.style.filter = "brightness(6.5) contrast(1.3)";
        img.style.backgroundColor = "rgb(58 98 157)";
      }, 1000);

      this.timeoutID3 = setTimeout(function () {
        img.style.backgroundColor = "rgb(121 187 255)";
        img.style.filter = "brightness(1.7)";
      }, 1000);

      this.timeoutID4 = setTimeout(function () {
        img.style.filter = "contrast(1.1) brightness(1.7)";
        img.style.backgroundColor = "rgb(85 127 255)";
      }, 1000);
    },
    resetScale() {
      const titles = document.querySelectorAll(".item-titre a");

      titles.forEach(title => {
        title.style.color = "wheat";
      });

      const filter = document.querySelector('#imgFilm');
      const img = document.querySelector('.bg--film');
      const follower = document.querySelector(".cursor-follower");
      const headerRect = event.currentTarget.getBoundingClientRect();
      const distanceFromCenter = Math.sqrt((event.clientX - headerRect.left - headerRect.width / 2) ** 2 + (event.clientY - headerRect.top - headerRect.height / 2) ** 2);
      const maxDistance = Math.sqrt((headerRect.width / 2) ** 2 + (headerRect.height / 2) ** 2);
      const progress = distanceFromCenter / maxDistance;
      const timeToDefaultScale = progress * 4;
      this.scale = 1;
      follower.style.transition = `transform ${timeToDefaultScale}s`;
      follower.style.transform = `scale(${this.scale})`;
      follower.classList.add('home');

      filter.style.filter = "contrast(1) brightness(1)";
      img.style.backgroundColor = "transparent";
      img.style.filter = "brightness(1) contrast(1)";

      clearTimeout(this.timeoutID1);
      clearTimeout(this.timeoutID2);
      clearTimeout(this.timeoutID3);
      clearTimeout(this.timeoutID4);
    },
    toggleScale() {
      const img = document.querySelector('.bg--film');
      img.classList.toggle('inversion');
    },
    randomPictureOnLoad() {
      const bgFilm = document.querySelector('.bg--film');
      const img = bgFilm.querySelector('img');
      const images = this.ListImages;
      if (this.currentImageSrc) {
        img.src = this.currentImageSrc;
      } else {
        const randomIndex = Math.floor(Math.random() * images.length);
        const selectedImage = images[randomIndex];
        img.src = selectedImage.src;
        img.style.cssText = selectedImage.style;
        const selectedImageClass = selectedImage.class;
        img.classList.add(selectedImageClass);
      }
    },
    changeRiviere() {
      const itemImg = document.querySelector('.item-img.riviere--home');
      setTimeout(() => {
        itemImg.style.opacity = '1';
      }, 300); // Delay to allow the transition to take effect


    },
    changeFictionDiurne() {
      const itemImg = document.querySelector('.item-img.fiction--home');

      setTimeout(() => {
        itemImg.style.opacity = '1';
      }, 300); // Delay to allow the transition to take effect


    },
    changeChroniquesLatentes() {
      const itemImg = document.querySelector('.item-img.chroniques--home');

      setTimeout(() => {
        itemImg.style.opacity = '1';
      }, 300); // Delay to allow the transition to take effect


    },
    changePuzzle() {
      const itemImg = document.querySelector('.item-img.puzzle--home');

      setTimeout(() => {
        itemImg.style.opacity = '1';
      }, 300); // Delay to allow the transition to take effect



    },
    changePetiteChose() {
      const itemImg = document.querySelector('.item-img.chose--home');
      setTimeout(() => {
        itemImg.style.opacity = '1';
      }, 300); // Delay to allow the transition to take effect
    },
    resetImageRiviere() {
      const itemImg = document.querySelector('.item-img.riviere--home');
      setTimeout(() => {
        itemImg.style.opacity = '0';
      }, 400);
    },
    resetImageFiction() {
      const itemImg = document.querySelector('.item-img.fiction--home');
      setTimeout(() => {
        itemImg.style.opacity = '0';
      }, 400);
    },
    resetImageChroniques() {
      const itemImg = document.querySelector('.item-img.chroniques--home');
      setTimeout(() => {
        itemImg.style.opacity = '0';
      }, 400);
    },
    resetImagePuzzle() {
      const itemImg = document.querySelector('.item-img.puzzle--home');
      setTimeout(() => {
        itemImg.style.opacity = '0';
      }, 400);
    },
    resetImageChose() {
      const itemImg = document.querySelector('.item-img.chose--home');
      setTimeout(() => {
        itemImg.style.opacity = '0';
      }, 400);
    },
    showEmailDiv(event) {
      const emailDiv = document.getElementById("email");
      const cursor = document.querySelector(".cursor");
      const follower = document.querySelector(".cursor-follower");

      emailDiv.style.opacity = "1";
      emailDiv.style.pointerEvents = "auto";

      emailDiv.style.top = event.clientY + "px";
      emailDiv.style.left = event.clientX + "px";

      emailDiv.addEventListener('mouseover', function () {
        emailDiv.style.opacity = "1";
        emailDiv.style.pointerEvents = "auto";
        cursor.classList.add('no-cursor');
        follower.classList.add('no-cursor');
      })

      emailDiv.addEventListener('mouseleave', function () {
        emailDiv.style.opacity = "0";
        emailDiv.style.pointerEvents = "none";
        cursor.classList.remove('no-cursor');
        follower.classList.remove('no-cursor');
      })
    }
  },
  mounted() {
    const cursor = document.querySelector(".cursor");
    const follower = document.querySelector(".cursor-follower");
    const linksCursor = document.querySelectorAll(".link");
    const portfolioLinks = document.querySelectorAll(".item-titre a");
    const jason = document.querySelector(".link-blue");
    const header = document.querySelector('.header');
    const traitement = document.querySelector('.bg--film');
    const PointerItem = document.querySelector('.items');
    let isFilmInverted = false;

    this.randomPictureOnLoad();

    PointerItem.style.pointerEvents = 'none';

    setTimeout(() => {
      document.body.classList.remove('antiqueBG');
      document.body.classList.remove('zoomBG');
      document.body.classList.add('grainBG');
      document.body.style = "";
      cursor.classList.add('info');
      follower.classList.add('info');
    }, 50);

    setTimeout(function () {
      cursor.classList.remove('active7');
      cursor.classList.remove('active5');
      cursor.classList.remove('active5-white-2');
      cursor.classList.remove('active2');
      follower.classList.remove('active7');
      follower.classList.remove('active2');
      follower.classList.remove('active3');
    }, 300);

    setTimeout(() => {
      PointerItem.style.pointerEvents = '';
    }, 1200);

    //BACKGROUND CHANGE + CURSOR CHANGE + VIEWPORT WIDTH AT THE END
    header.addEventListener('click', function () {
      if (window.innerWidth > 1025) {
        if (isFilmInverted) {
          setTimeout(function () {
            isFilmInverted = false;
          }, 1000);
        } else {
          isFilmInverted = true;
        }
      }
    });

    // CURSOR CHANGE 1 INFO
    linksCursor.forEach(linkCursor => {
      linkCursor.addEventListener("mouseenter", function () {
        cursor.classList.add("active");
        follower.classList.add("active");
      });
      linkCursor.addEventListener("mouseleave", function () {
        cursor.classList.remove("active");
        follower.classList.remove("active");
      });
    });

    //CURSOR CHANGE 2 ITEMS
    portfolioLinks.forEach(itemTitre => {
      itemTitre.addEventListener("mouseenter", function () {
        cursor.classList.add("active2");
        follower.classList.add("active2");
      });
      itemTitre.addEventListener("mouseleave", function () {
        cursor.classList.remove("active2");
        follower.classList.remove("active2");
      });
    });

    //CURSOR CHANGE 3 HEADER
    jason.addEventListener("mouseenter", function () {
      cursor.classList.add("active2");
      follower.classList.add("active2");
    });
    jason.addEventListener("mouseleave", function () {
      cursor.classList.remove("active2");
      follower.classList.remove("active2");
    });
    jason.addEventListener("click", function () {
      cursor.classList.remove("active2");
      follower.classList.remove("active2");
    });

    header.addEventListener("mouseenter", function () {
      cursor.classList.add("active2");
      follower.classList.add("active2");
      traitement.classList.add("active-color");

    });
    header.addEventListener("mouseleave", function () {
      cursor.classList.remove("active2");
      follower.classList.remove("active2");
      traitement.classList.remove("active-color");
    });

    header.addEventListener("click", function () {
      cursor.classList.remove("active2");
      follower.classList.remove("active2");
      traitement.classList.remove("active-color");
    });

  },
  unmounted() {
    const cursor = document.querySelector(".cursor");
    const follower = document.querySelector(".cursor-follower");
    cursor.classList.remove('active');
    follower.classList.remove('active');
    document.body.classList.remove('blackBG');
    follower.classList.remove('home');
    follower.style = "";
  }
}
</script>

<style lang="scss"></style>