<template>
  <div class="move" @contextmenu.prevent="showEmailDiv">

    <div class="main--gallery theme--two" @touchstart="handleTouchStart" @gesturechange="handleGestureChange"
      @touchmove="handleTouchMove" @touchend="handleTouchEnd" ref="elementToDisableSwipeAndZoom">

      <div ref="descriptionFirst" class="dark--grain description item current" @click.stop="disableClick">

        <div class="bottom-right">
          <div class="main--img one">
            <div class="layer--black"></div>
            <img ref="pictureItem" src="../../assets/img/puzzle/jason-pumo_puzzle_main.jpg" alt="" />
          </div>
        </div>
      </div>

      <figure v-motion="fadeText" v-for="(image, index) in images"
        :key="index === images.length - 1 ? 'lastPicture' : index" :ref="'pictureItem' + index"
        :class="['picture FADEIN red-pointer', 'item dark center-self', image.class, { 'lastItem': index === images.length - 1 }]">
        <div :class="['image-container gallery--one', image.classContainer]">
          <div :class="['image-item leg-left', image.classDiv1]">
            <img v-if="image.src" :src="image.src" :class="image.class1" :alt="altText" />
          </div>
          <div :key="'textSection' + index" v-if="image.hasText" :class="['centered-text', image.classText]">
            <h1 v-html="image.text"></h1>
          </div>
          <div :class="['caption', image.classCaption]">
            <figcaption v-html="image.title" :class="['desktop', image.classText]"></figcaption>
          </div>
        </div>
        <figcaption v-html="image.title" :class="['mobile', image.classText]"></figcaption>
      </figure>

    </div>
    <MenuGallery />

    <div class="figure-counter pages opacity-zero" style="pointer-events: none" v-motion="fade4">
      <span class="current-counter">{{ currentImageIndex }}</span><span class="slash-counter"> — </span> <span
        class="total-counter">{{ totalFigures }}</span>
    </div>

    <div class="red-pointer loader--gif">
      <h1 class="theme--two">{{ documentTitle }}</h1>
      <div class="img--home">
        <div class="layer--black"></div>
        <img :key="currentImage" src="../../assets/img/puzzle/jason-pumo_puzzle_main.jpg" />
      </div>
    </div>

  </div>
</template>

<script>
import GalleryScript from '../../directives/GalleryScript.js';
import ThemeTwo from '../../directives/ThemeTwo.js';
import GalleryScriptObserver from '../../directives/GalleryScriptObserver.js';
import { fade2, fadeText, fade3, fade4 } from '../../directives/AnimationHome.js';

import MenuGallery from "../menu/MenuGallery.vue";
// import { pageview } from 'vue-gtag'

export default {
  name: 'PuzzlePage',
  components: { MenuGallery },
  mixins: [GalleryScript, ThemeTwo, GalleryScriptObserver],
  // setup() {
  //   const track = () => {
  //     pageview({ page_path: '/puzzle' });
  //     pageview('/puzzle');
  //   }
  //   return {
  //     track
  //   }
  // },
  data() {
    return {
      documentTitle: document.title,
      currentImageIndex: 0,
      currentIndex: null,
      fade2, fadeText, fade3, fade4,
      altText: "Jason Pumo, Puzzle, photographie, rolleiflex, 6x6, noir et blanc, black and white, moyen format, argentique, analog, film, picture, music, musique, concert, people",
      images: [
        {
          hasText: true, src: '', title: '', class: 'cartel-text xl-pad-left xl-pad-right first red-pointer', classText: 'mobile-fit',
          text: `<span class="titre">Puzzle</span><span class="annee">— &nbsp; 2015 . 2018</span>
          <div class="ensemble">
            <div class="francais">
          <p>La sur-exposition du film noir et blanc conforte le cliché dans une
                  sécurité, celle de ne pas laisser échapper la moindre information lors de la prise de vue.
                  Un négatif sous-exposé est souvent vu comme une photographie ratée, mal exposée.
                  Pourtant, sa sous-exposition insinue une assurance, celle de laisser encore une chance à l'image
                  de révéler autre chose de ce qui devait être capturé.
                  <i>Puzzle</i> explore cette lumière noire, analogie de la transparence de la pellicule.</p>
            </div>
            <div class="anglais">
          <p>The over-exposure of black and white film gives the photograph the security of not letting any
                  information escape during the shooting process.
                  An underexposed negative is often seen as a failed, poorly exposed photograph.
                  Yet its underexposure insinuates an assurance, that of still giving the image a chance to reveal
                  something else of what was meant to be captured.
                  <i>Puzzle</i> explores this black light, analogous to the transparency of film.</p>
            </div>
            </div>
            <div class="ensemble">
            <div class="francais">
          <p>Réalisées en France entre 2015 et 2018, les photographies suivantes font l'objet d'exister de par leur contenance de lumière inachevée.
                  Si l'intention première était d'expérimenter les différents aspects que l'argentique offre au noir et blanc,
                  elles sont la rémanence d'une certaine époque.</p>
            </div>
            <div class="anglais">
          <p> Taken in France between 2015 and 2018, the following photographs are the object of existence due to their unfinished light content.
                  If the original intention was to experiment with the different aspects that film offers to black and white,
                  they are the afterglow of a certain era.</p>
            </div>
            </div>
            <div class="ensemble">
            <div class="francais">
          <p>*<i>projet en cours d'auto-édition</i></p>
            </div>
            <div class="anglais">
          <p>*<i>work in progress</i></p>
            </div>
            </div>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_1.jpg'),
          title: `En concert<span>Cannes, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_2.jpg'),
          title: `Sans titre<span>Cannes, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_3.jpg'),
          title: `Nouvelle année<span>Cannes, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_4.jpg'),
          title: `Concert à la chapelle de la Providence<span>Nice, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_5.jpg'),
          title: `Sans titre<span>Vallauris, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_6.jpg'),
          title: `Expérimentations<span>Cannes, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_7.jpg'),
          title: `Sans titre<span>Cannes, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_8.jpg'),
          title: `Sans titre<span>Nice, 2016</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_9.jpg'),
          title: `Reflet<span>Cannes, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_10.jpg'),
          title: `Splendid<span>Cannes, 2017</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_11.jpg'),
          title: `Concert à la Station<span>Nice, 2018</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_11-2.jpg'),
          title: `Sans titre<span>Nice, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_12.jpg'),
          title: `Sans titre<span>Cannes, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_13.jpg'),
          title: `Sans titre<span>Thorenc, 2018</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_14.jpg'),
          title: `Sans titre<span>Cannes, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_14-2.jpg'),
          title: `Sans titre<span>Toulon, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_14-3.jpg'),
          title: `Sans titre<span>Nice, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_15.jpg'),
          title: `Sans titre<span>Monaco, 2017</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_16.jpg'),
          title: `Sans titre<span>Nice, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_16-2.jpg'),
          title: `Sans titre<span>Cannes, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_17.jpg'),
          title: `Concert au Jardin Exotique<span>Monaco, 2017</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_18.jpg'),
          title: `Sans titre<span>Nice, 2018</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_19.jpg'),
          title: `Sans titre<span>Nice, 2015</span>`
        },
        {
          class1: 'single-img large-view',
          classTitre1: 'legende-single',
          src: require('../../assets/img/puzzle/jason-pumo_puzzle_20.jpg'),
          title: `Sans titre<span>Cannes, 2015</span>`
        },
      ],
    }
  },
  mounted() {
    document.querySelector('.info--edition').classList.add('index-of-puzzle');

    const IndexPuzzle = document.querySelector('.index-of-puzzle');

    function gtag() {
      window.dataLayer.push(arguments);
    }

    IndexPuzzle.addEventListener('click', () => {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'index_puzzle', {
        'event_category': 'Click',
        'event_label': 'click_on_index_puzzle'
      });
    });

    const indexMobile = document.querySelector('.home--mobile');
    indexMobile.addEventListener('click', () => {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'index_puzzle', {
        'event_category': 'Click',
        'event_label': 'click_on_index_puzzle'
      });
    });

    const lastItem = document.querySelector('.lastItem');

    if (lastItem.classList.contains("current")) {
      // Replace 'track-category' and 'track-action' with your own values
      gtag('event', 'reach_end_puzzle', {
        'event_category': 'Click',
        'event_label': 'reach_end_puzzle'
      });
    }
  },
  unmounted() {
    document.querySelector('.info--edition').classList.remove('index-of-puzzle');
  }
}
</script>