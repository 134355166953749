export default {
  mounted() {
    const cursor = document.querySelector(".cursor");
    const follower = document.querySelector(".cursor-follower");
    const buttonLeft = document.querySelector('.button-scroll .button-left');
    const indexGallery = document.querySelector('.index-gallery');
    const deviceMenu = document.querySelector('.device--menu');
    const documentTitle = document.querySelector('.index-gallery.documentTitle');
    const indexGalleryMobile = document.querySelector('.home--mobile');
    const home = document.querySelector('.titre--site.desktop');
    const mainImg = document.querySelector('.main--img');
    const OverlayImg = document.querySelector('.overlay-img');
    const background = document.querySelector('.galleryBG');
    const menuEdition = document.querySelector('.menu--edition');
    const editionRight = document.querySelector('.right--edition');
    const index = document.querySelector('.index-gallery.documentTitle');
    const figcaptions = document.querySelectorAll('figcaption');
    const buttonRight = document.querySelector('.button-scroll .button-right');
    const flecheHome = document.querySelector('.titre--site');
    const counter = document.querySelector('.figure-counter.pages');
    let GridView = document.querySelector('.main--gallery');

    // Sélectionnez tous les éléments .image-item
    const imageItems = document.querySelectorAll('.image-item');

    // Parcourez chaque élément .image-item
    imageItems.forEach(imageItem => {
      // Vérifiez si l'élément actuel a la classe .reverse
      if (imageItem.classList.contains('reverse')) {
        // Trouvez l'élément .image-container correspondant
        const imageContainer = imageItem.closest('.image-container');

        // Appliquez la règle flex-direction: row-reverse à l'élément .image-container correspondant
        if (imageContainer) {
          imageContainer.style.flexDirection = 'row-reverse';
        }
      }
    });

    // Sélectionnez tous les éléments .image-container
    const imageContainers = document.querySelectorAll('.image-container');

    // Parcourez chaque élément .image-container
    imageContainers.forEach(imageContainer => {
      // Vérifiez si l'élément actuel a un enfant div avec la classe .centered-text
      const hasCenteredText = imageContainer.querySelector('.centered-text') !== null;

      // Si l'élément .centered-text existe, supprimez l'élément .caption
      if (hasCenteredText) {
        const caption = imageContainer.querySelector('.caption');
        if (caption) {
          caption.remove();
        }
      }
    });

    // OBSERVER + DEFAULT
    let description = document.querySelector('.description.item');
    let observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          if (description.classList.contains('current')) {
            indexGallery.classList.remove('opacity-zero');
            cursor.classList.remove("active5-2");
            cursor.classList.remove("active5-white-2");
            cursor.classList.remove("active5-white");
            follower.classList.remove("active4-2");
            follower.classList.remove("active3");
            indexGallery.classList.remove('black111');
            menuEdition.style.opacity = "0";
            deviceMenu.style.opacity = '0';

            cursor.classList.add('cursor-blanche');
            follower.classList.add('cursor-blanche2');
            indexGallery.classList.add('blanche');
            documentTitle.classList.add('opacity-zero');
            deviceMenu.classList.add('opacity-zero');
            counter.classList.add('opacity-zero');
            home.classList.add('blanche');
            indexGalleryMobile.classList.add('blanche');
            buttonLeft.classList.add('debut');
            OverlayImg.classList.remove('opacity-zero');

            home.addEventListener("click", function () {
              mainImg.style.opacity = '1';
            });
          } else {
            documentTitle.classList.remove('opacity-zero');
            setTimeout(function () {
              counter.classList.remove('opacity-zero');
              deviceMenu.style.opacity = '';
            }, 400);
            menuEdition.style.opacity = "1";
            indexGallery.classList.add('opacity-zero');
            buttonLeft.classList.remove('debut');
            home.classList.remove('blanche');
            indexGalleryMobile.classList.remove('blanche');
            OverlayImg.classList.add('opacity-zero');
            cursor.classList.remove('cursor-blanche');
            follower.classList.remove('cursor-blanche2');
            home.addEventListener("click", function () {
              mainImg.style.opacity = '0';
            });
          }
          if (GridView.classList.contains('grid-view')) {
            home.classList.remove('blanche');
            cursor.classList.add("active5-2");
            follower.classList.add("active4-2");
          }
        }
      });
    });
    observer.observe(description, { attributes: true });

    // OBSERVER + LAST ITEM
    let lastItem = document.querySelector('.lastItem');
    let observerEnd = new MutationObserver(function (mutations2) {
      mutations2.forEach(function (mutation) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          if (lastItem.classList.contains('current')) {
            buttonLeft.classList.add('end');
            editionRight.classList.add('opacity-zero-2');
          } else {
            buttonLeft.classList.remove('end');
            editionRight.classList.remove('opacity-zero-2');
          }
        }
      });
    });
    observerEnd.observe(lastItem, { attributes: true });

    // OBSERVER + BLANCHE
    let papers = document.querySelectorAll('.paper');
    papers.forEach(function (paper) {
      let observerPaper = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            if (description.classList.contains('current')) {
              cursor.classList.remove('active5-white');
              background.classList.remove('blackBG');
              background.classList.remove('antiqueBG');
              background.classList.remove('neutreBG');
              background.classList.add('blancheBG');
              flecheHome.classList.add('blanche');
            } else {
              background.classList.add('blancheBG');
              flecheHome.classList.remove('blanche');
              cursor.classList.add('active5-white');
            }
          }
        });
      });
      observerPaper.observe(paper, { attributes: true });
    });


    // OBSERVER + ANTIQUE
    let antiques = document.querySelectorAll('.antique');
    antiques.forEach(function (antique) {
      let observerAntique = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            if (description.classList.contains('current')) {
              cursor.classList.remove('active5-white');
              background.classList.remove('blackBG');
              background.classList.remove('neutreBG');
              background.classList.add('antiqueBG');
              flecheHome.classList.add('blanche');
            } else {
              flecheHome.classList.remove('blanche');
              cursor.classList.add('active5-white');
              background.classList.add('antiqueBG');
            }
          }
        });
      });
      observerAntique.observe(antique, { attributes: true });
    });


    // OBSERVER + GRIS
    let grise = document.querySelectorAll('.gris');
    grise.forEach(function (gris) {
      let observerGris = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            if (description.classList.contains('current')) {
              cursor.classList.remove('active5-white');
              background.classList.remove('blackBG');
              background.classList.remove('blancheBG');
              background.classList.remove('antiqueBG');
              background.classList.remove('neutreBG');
              background.classList.add('blackBG');
              flecheHome.classList.add('blanche');
            } else {
              background.classList.add('griseBG');
              flecheHome.classList.remove('blanche');
              cursor.classList.add('active5-white');
            }
          }
        });
      });
      observerGris.observe(gris, { attributes: true });
    });


    // OBSERVER + WHITE
    let whites = document.querySelectorAll('.white');
    whites.forEach(function (white) {
      let whiteObserver = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            if (white.classList.contains('current')) {
              setTimeout(function () {
                background.classList.remove('blackBG');
                background.classList.remove('antiqueBG');
                background.classList.remove('blancheBG');
                background.classList.add('neutreBG');
              }, 50);
            } else {
              background.classList.remove('neutreBG');
            }
          }
        });
      });
      whiteObserver.observe(white, { attributes: true });
    });

    // OBSERVER + DARK
    let darks = document.querySelectorAll('.dark');
    darks.forEach(function (dark) {
      let darkObserver = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            if (dark.classList.contains('current')) {
              setTimeout(() => {
                // buttonLeft.classList.remove('black-arrow');
                // buttonRight.classList.remove('black-arrow');
                background.classList.remove('blancheBG');
                background.classList.remove('neutreBG');
                background.classList.remove('antiqueBG');
                background.classList.add('blackBG');
                flecheHome.classList.add('blanche');
                index.classList.add('blanche');
                figcaptions.forEach(figcaption => figcaption.classList.add('blanche'));
                menuEdition.classList.add('blanche-dalmond');
                buttonLeft.classList.add('yellow-arrow');
                buttonRight.classList.add('yellow-arrow');
              }, 1);
            } else {
              background.classList.remove('blackBG');
              flecheHome.classList.remove('blanche');
              index.classList.remove('blanche');
              figcaptions.forEach(figcaption => figcaption.classList.remove('blanche'));
              menuEdition.classList.remove('blanche-dalmond');
              buttonLeft.classList.remove('yellow-arrow');
              buttonRight.classList.remove('yellow-arrow');
            }
          }
        });
      });

      darkObserver.observe(dark, { attributes: true });

    });

    // OBSERVER + DARK
    let grains = document.querySelectorAll('.dark--grain');
    grains.forEach(function (grain) {
      let grainObserver = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            if (grain.classList.contains('current')) {
              setTimeout(() => {
                // buttonLeft.classList.remove('black-arrow');
                // buttonRight.classList.remove('black-arrow');
                background.classList.remove('blancheBG');
                background.classList.remove('neutreBG');
                background.classList.remove('antiqueBG');
                background.classList.add('grainBG');
                flecheHome.classList.add('blanche');
                index.classList.add('blanche');
                figcaptions.forEach(figcaption => figcaption.classList.add('blanche'));
                menuEdition.classList.add('blanche-dalmond');
                buttonLeft.classList.add('yellow-arrow');
                buttonRight.classList.add('yellow-arrow');
              }, 1);
            } else {
              background.classList.remove('grainBG');
              flecheHome.classList.remove('blanche');
              index.classList.remove('blanche');
              figcaptions.forEach(figcaption => figcaption.classList.remove('blanche'));
              menuEdition.classList.remove('blanche-dalmond');
              buttonLeft.classList.remove('yellow-arrow');
              buttonRight.classList.remove('yellow-arrow');
            }
          }
        });
      });

      grainObserver.observe(grain, { attributes: true });

    });
  }
}